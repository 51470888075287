import { PawsRegistrationComponent } from './components/paws-registration/paws-registration.component';
import { HomePageComponent } from './components/homepage/homepage.component';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import { TmDashboardComponent } from './components/tm-dashboard/tm-dashboard.component';
import { TlDashboardComponent } from './components/tl-dashboard/tl-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './components/custom/calendar/calendar.component';
import { AdminRequestDashboardComponent } from './components/admin-request-dashboard/admin-request-dashboard.component';
import { AdminScheduleDashboardComponent } from './components/admin-schedule-dashboard/admin-schedule-dashboard.component';
import { UpdateRegistrationDetailsComponent } from './components/update-registration-details/update-registration-details.component';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { UpdateScheduleDetailsComponent } from './components/update-schedule-details/update-schedule-details.component';
import { AuthenticationGuard, AuthorizationGuard } from './auth';

const routes: Routes = [
  { path: 'home', component: HomePageComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'paw-registration', component: PawsRegistrationComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'calendar', component: CalendarComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'paw-schedule', component: ScheduleFormComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'tm-dashboard', component: TmDashboardComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'tl-dashboard', component: TlDashboardComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'admin-request-dashboard', component: AdminRequestDashboardComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'admin-schedule-dashboard', component: AdminScheduleDashboardComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  { path: 'impersonation', component: ImpersonationComponent, canActivate: [AuthenticationGuard, AuthorizationGuard] },
  {
    path: 'update-registration-details/:partitionkey/:sortKey', component: UpdateRegistrationDetailsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'update-schedule-details/:partitionkey/:sortKey', component: UpdateScheduleDetailsComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

