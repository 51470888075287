<!-- <app-jumbotron header="">
</app-jumbotron> -->

<div class="homepage">
  <div class="left-content">
    <div class="bar"></div>
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">
      Bring Your Furry Friend to Work!
    </h1>
    <p class="rkt-Body-16 rkt-mt4">
      Paws At Work lets you spend the day with your dog. Get started by
      <a rktLink idString="default-link"
        routerLink="/paw-registration"
        routerLinkActive="active"
        >applying
      </a>
      today or
      <a rktLink idString="default-link"
        routerLink="/paw-schedule"
        routerLinkActive="active"
        >scheduling
      </a>
      a visit if your dog is already approved.
    </p>
  </div>
  <div class="right-content">
    <div class="image-section random" [ngStyle]="{'animation-duration': images.length * imageCycleTime + 's'}">
      <img
        class="image-loop"
        *ngFor="let image of images; index as i"
        [src]="image"
        [ngStyle]="{'animation-delay': i * imageCycleTime * (-1) + 's', 'animation-duration': images.length * imageCycleTime + 's'}"
      />
    </div>

    <div class="shape"></div>
    <div class="tag"></div>
    <div class="paw"></div>
  </div>
</div>
