import { ApplicationStatusEnum, RequestTypeEnum } from './Enums';
import { TeamMember } from './teamMember';

export interface IDropDownData {
    key: string | number;
    value: string | number;
}

export class FormBase {
    public CreateDate?: Date = new Date();
    public TeamMember?: TeamMember;
    public RequestType?: RequestTypeEnum;
    public Status?: ApplicationStatusEnum | string;
    public ReasonforDenial?: string;
    public PartitionKey?: string;
    public SortKey?: string;
    public Seating?: string;
    public scheduleDate?: Date;
    public hasOffice?: boolean;
}
