import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnInit, OnChanges {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @Input() columnDefs: ColDef[];
  @Input() rowData: any[];
  @Input() isDataLoading: boolean;
  @Input() frameworkComponents: any;
  @Input() noRowsMessage: string;
  overlayNoRowsTemplate: string;
  constructor() { }

  ngOnInit() {
    if (!this.noRowsMessage) {
      this.noRowsMessage = 'No rows to display';
    }
    this.overlayNoRowsTemplate = '<span>' + this.noRowsMessage + '</span > ';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rowData']) {
      setTimeout(() => {
        this.setGridOptions();
      });
    }
    if (changes['isDataLoading'] && !changes['isDataLoading'].isFirstChange()) {
      this.setGridLoading();
    }
  }

  setGridLoading() {
    setTimeout(() => {
      if (this.isDataLoading) {
        this.agGrid.api.showLoadingOverlay();
      } else if (!this.isDataLoading && this.rowData.length !== 0) {
        this.agGrid.api.hideOverlay();
      }
    });
  }
  setGridOptions() {
    this.agGrid.api.sizeColumnsToFit();
    this.agGrid.api.setDomLayout('autoHeight');
    this.agGrid.api.setAlwaysShowVerticalScroll(true);
  }

  public get paginationPageSize(): number {
    return environment.ui.gridPaginationPageSize;
  }
}
