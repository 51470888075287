import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { ApplicationStatusEnum, UserTypeEnum } from 'src/app/models/Enums';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationRequest } from 'src/app/models/registrationRequest';

@Component({
  selector: 'app-update-registration-details',
  templateUrl: './update-registration-details.component.html'
})
export class UpdateRegistrationDetailsComponent implements OnInit {
  isSubmitted: boolean;
  userType: string;
  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const partitionkey = this.route.snapshot.paramMap.get('partitionkey');
    const sortKey = this.route.snapshot.paramMap.get('sortKey');
    this.userType = UserTypeEnum[this.route.snapshot.queryParams['userType']];
    if (this.userType && partitionkey && sortKey) { // Routing params present, load data
      this.getRegistrationRequest(partitionkey, sortKey);
      return;
    }  // If routing params not present ,Angular router will redirect to home page
    this.router.navigate(['/home']);
  }

  public get request(): RegistrationRequest {
    return this.registrationService.UIModel;
  }
  public set request(value: RegistrationRequest) {
    this.registrationService.UIModel = value;
  }
  public getRegistrationRequest(partitionKey: string, sortKey: string): void {
    this.registrationService.getRegistrationRequest(partitionKey, sortKey);
  }

  public async onUpdate(updateRegistrationForm: NgForm) {
    this.isSubmitted = true;
    if (updateRegistrationForm.valid) {
      await this.registrationService.updateRegistrationStatus(ApplicationStatusEnum[this.registrationService.UIModel.Status],
        this.registrationService.UIModel);
      this.router.navigate([this.redirectRouteValue]);
    }
  }
  public get showPulseAdminApprovalForm(): boolean {
    return this.userType === UserTypeEnum.PulseAdmin;
  }
  public get showTeamLeaderApprovalForm(): boolean {
    return this.userType === UserTypeEnum.TeamLeader;
  }
  public get redirectRouteValue(): string {
    if (this.showPulseAdminApprovalForm) {
      return '/admin-request-dashboard';
    }
    if (this.showTeamLeaderApprovalForm) {
      return '/tl-dashboard';
    }
  }
  public get showTemplate(): boolean {
    return !!(this.request && this.request.TeamMember && this.request.Status && this.request.TeamMember.TMCommonId);
  }
}
