import { Injectable, Injector } from '@angular/core';
import { NotificationMessageType, NotificationMessage } from '../../models/notification';
import { v4 } from 'uuid';
import { some, remove } from 'lodash';
import { IUserNotificationService } from './user-notification.interface';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserNotificationService implements IUserNotificationService {
  userNotifications: NotificationMessage[];

  constructor(private injector: Injector) {
    this.userNotifications = [];
  }

  public get hasErrorNotifications(): boolean {
    return some(this.userNotifications, (n) => n.MessageType === NotificationMessageType.Error);
  }

  public addMessage(message: string, messageType: NotificationMessageType): void {
    const notification: NotificationMessage = { MessageType: messageType, UserMessage: message, MessageId: this.generateMessageId() };
    this.userNotifications.push(notification);
  }

  public addSuccessMessage(message: string): void {
    this.addMessage(message, NotificationMessageType.Info);
  }

  public addErrorMessage(message: string): void {
    this.addMessage(message, NotificationMessageType.Error);
  }

  public handleError(err: any): void {
    console.log(err);
    if (err.status === 401) {
      this.addErrorMessage('You are not authorized to do this operation.');
      this.router.navigate(['/home']);
      return;
    }
    const mailText = '\"mailto:RCDSoftwareEngineeringSupport@rocketcentral.com?subject=Error from PawsAtWork: ' +
      '[' + environment.env + ']' +
      '&body=' + this.prepareEmailBody(err) + '\"';

    const errorMsg = 'There has been some problem with this request. If the problem persists, then please contact ' +
      '<a href=' + mailText + '>Support</a>.';

    this.addErrorMessage(errorMsg);
  }

  private prepareEmailBody(err: any) {
    return 'Below are the error details-' +
      '%0DStatus Code: ' + err.status +
      '%0DMessage: ' + err.message +
      '%0DThanks,' +
      '%0DPawsAtWork Support';
  }

  public clear(messageId: string) {
    remove(this.userNotifications, (i) => i.MessageId === messageId);
  }

  private generateMessageId(): string {
    return v4();
  }
  // This is need to prevent circular dependency error
  public get router(): Router {
    return this.injector.get(Router);
  }
}
