import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html'
})
export class ImpersonationComponent implements OnInit {
  constructor(private currentUserService: CurrentUserService) {
  }

  ngOnInit() {
  }

  public get showCurrentlyImpersonatingMessage(): boolean {
    return this.currentUserService.isImpersonating;
  }

  public get impersonatingCommonId(): string {
    return this.currentUserService.impersonatingCommonId;
  }

  public toggleImpersonation(commonIdToImpersonate?: string): void {
    this.currentUserService.impersonatingCommonId = commonIdToImpersonate;
  }
}
