export enum NotificationMessageType {
    Info = 1,
    Warning = 2,
    Error = 3
}
export interface NotificationMessage {
    MessageType: NotificationMessageType;
    UserMessage: string;
    MessageId: string;
}
