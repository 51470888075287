<fieldset ngModelGroup="pulseAdminSection">
  <legend class="h4">Pulse Admin Section</legend>
  <div class="form-row" *ngIf="showTemplate">
    <div class="form-group col-lg-3">
      <label class="required-field">Current Status</label>
      <input type="text" id="currentStatus" value="{{initialStatus}}" disabled class="form-control"
        name="currentStatus" />
    </div>
    <div class="form-group col-lg-3">
      <label for="applicationStatus" class="required-field">New Status</label>
      <select required id="applicationStatus" class="form-control" name="applicationStatus" [(ngModel)]="newStatus"
        (change)="request.Status=newStatus">
        <option [ngValue]="option.key" *ngFor="let option of statusOptions">{{option.value}}</option>
      </select>
      <div class="invalid-feedback">
        Please select New Status.
      </div>
    </div>
    <!-- Desk Assignment Section, removed for now(8/2/2022), will be needed in future per business -->
    <!-- <div *ngIf="!canBeApprovedByPulse && isScheduleRequest" class="form-group col-lg-3">
      <div *ngIf="!deniedByPulse(newStatus)">
        <label for="deskSelection" class="required-field">Desk Location</label>
        <select required id="deskSelection" class="form-control" name="deskSelection" [(ngModel)]="deskSelection"
          (change)="request.seating=deskSelection">
          <option [ngValue]="option.key" *ngFor="let option of deskOptions">{{option.value}}</option>
        </select>
      </div>
      <div *ngIf="deniedByPulse(newStatus)">
        <label for="deskSelection" class="required-field">Desk Location</label>
        <select disabled id="deskSelection" class="form-control" name="deskSelection">
        </select>
      </div>
    </div>
    <div *ngIf="canBeApprovedByPulse" class="form-group col-lg-6">
      <label class="required-field">No more seating options are available for this floor and day</label>
    </div>  -->
    <div *ngIf="canBeApprovedByPulse && isScheduleRequest" class="form-group col-lg-3">
      <div *ngIf="!deniedByPulse(newStatus)">
        <label for="assignmentSelection" class="required-field">Team Member Assignment</label>
        <select required id="assignmentSelection" class="form-control" name="assignmentSelection" [(ngModel)]="assignmentSelection"
          (change)="request.teamMemberAssignment=assignmentSelection">
          <option [ngValue]="option.key" *ngFor="let option of assignmentOptions">{{option.value}}</option>
        </select>
      </div>
      <div *ngIf="deniedByPulse(newStatus)">
        <label for="assignmentSelection" class="required-field">Team Member Assignment</label>
        <select disabled id="assignmentSelection" class="form-control" name="assignmentSelection">
        </select>
      </div>
    </div>
    <div *ngIf="!canBeApprovedByPulse" class="form-group col-lg-6">
      <label class="required-field">No more assignment options are available for this floor and day</label>
    </div>
    <div *ngIf="showReasonForDenial" class="form-group col-lg-6">
      <label for="reasonForDenial" class="required-field">Reason for Denial</label>
      <textarea required id="reasonForDenial" class="form-control" name="Reason for Denial"
        [(ngModel)]="request.ReasonforDenial"></textarea>
      <div class="invalid-feedback">
        Please enter some reason for denial.
      </div>
    </div>
  </div>
</fieldset>
