<fieldset ngModelGroup="dogvaccine">
    <legend class="h4">Dog Medical Information</legend>
    <p>
        Please upload your dog's up-to-date records for Bordetella & Rabies vaccines. Each file should be smaller than two megabytes (2 MB or 2048 KB).
    </p>
    <div class="form-row">
        <div class="form-group col-lg-6">
            <app-document-upload headerText="Upload Bordetella Vaccination Record"         
                [(content)]="request.BordetellaVaccineDocument" [(isInvalid)]="isBordetellaVaccineDocumentInvalid" (isInvalidChange)="onIsInvalidChange()"
                validationText="Please upload your dog's Bordetella vaccination record.">
            </app-document-upload>
        </div>
        <app-date-picker-form-group class="col-lg-6" label="Expiration Date" key="BordetellaExpiryDate"
            [(value)]="request.BordetellaExpiryDate" required="true"
            invalidFeedback="Please select the expiration date of your dog's Bordetella vaccination.">
        </app-date-picker-form-group>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-6">
            <app-document-upload headerText="Upload Rabies Vaccination Record"
                [(content)]="request.RabiesVaccineDocument" [(isInvalid)]="isRabiesVaccineDocumentInvalid" (isInvalidChange)="onIsInvalidChange()"
                validationText="Please upload your dog's rabies vaccination record.">
            </app-document-upload>
        </div>
        <app-date-picker-form-group class="col-lg-6" label="Expiration Date" key="RabiesExpiryDate"
            [(value)]="request.RabiesExpiryDate" required="true"
            invalidFeedback="Please select the expiration date of your dog's Rabies vaccination.">
        </app-date-picker-form-group>
    </div>
</fieldset>