import { Injectable } from '@angular/core';
import { IPawsApiService } from './paws-api.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IHttpResponse } from 'src/app/models/httpResponse';

@Injectable()
export class PawsApiService implements IPawsApiService {

  constructor(private http: HttpClient) {
  }

  public get<T, K>(url: string, data: K): Observable<T> {
    const params = this.getHttpParams(data);
    return this.http.get<T>(`${url}`, { params });
  }

  public post<T>(url: string, data: T): Observable<IHttpResponse> {
    return this.http.post<any>(`${url}`, data, {});
  }

  public put<T>(url: string, item: T): Observable<IHttpResponse> {
    return this.http.put<any>(`${url}`, item, {});
  }

  private getHttpParams(obj: any): HttpParams {
    let httpParams = new HttpParams();
    if (obj) {
      Object.keys(obj).forEach(key => {
        if (obj[key] !== null) {
          httpParams = httpParams.append(key, obj[key]);
        }
      });
    }
    return httpParams;
  }
}
