import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarOptions, EventInput, PluginDef } from '@fullcalendar/core';
import { map } from 'lodash';
import { ScheduleStatusEnum } from 'src/app/models/Enums';
import { ScheduleRequestSearchService } from 'src/app/services/search/schedule-request-search.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {
  public calendarPlugins: PluginDef[] = [dayGridPlugin];
  public calendarOptions: CalendarOptions;
  private apiEndpoint = '/api/pawsCalendar';

  constructor(private scheduleRequestSearchService: ScheduleRequestSearchService) {
  }

  ngOnInit() {
    this.calendarOptions = {
      plugins: this.calendarPlugins,
      initialView: 'dayGridMonth',
      weekends: true,
      events: this.calendarEvents,
      eventDisplay: 'block',
      timeZone: 'UTC',
      dayMaxEventRows: 4,
      displayEventTime: false,
      fixedWeekCount: false,
      datesSet: this.loadEvents,
      height: 920
    };
  }

  public get calendarEvents(): EventInput[] {
    const scheduleRequests = this.scheduleRequestSearchService.data;
    return map(scheduleRequests, (item) => {
      const calendarEvent = {
        title: `${item.TeamMember.TMName} (${item.TeamMember.Location} - ${item.TeamMember.TMFloor})`,
        start: new Date(item.scheduleDate)
      };
      return calendarEvent;
    });
  }

  public loadEvents = async (dateInfo) => {
    this.scheduleRequestSearchService.searchParams = {
      Status: ScheduleStatusEnum.ApprovalEmailSent,
      ScheduleStartDate: dateInfo.start,
      ScheduleEndDate: dateInfo.end
    };
    const status: ScheduleStatusEnum[] = [ScheduleStatusEnum.ApprovalEmailSent, ScheduleStatusEnum.ApprovedByPulse];
    await this.scheduleRequestSearchService.loadScheduleRequestsBySearchParamsWithMultipleStatus(this.apiEndpoint, status);
    this.calendarOptions.events = this.calendarEvents;
  };
}
