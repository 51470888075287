<div class="form-group">
  <label for="{{key}}" [class.required-field]="required">{{label}}</label>
  <div class="input-group">
    <input ngbDatepicker [id]="key" [name]="key" class="form-control" placeholder="YYYY-MM-DD" #dp="ngbDatepicker"
      #control="ngModel" [(ngModel)]="value" (ngModelChange)="modelChange()" [required]="required" autocomplete="off"
      [minDate]="minDate" firstDayOfWeek="7" [disabled]="disabled" [class.is-invalid]="isInvalid">
    <div class="input-group-append">
      <button class="btn btn-outline-primary" (click)="dp.toggle()" type="button" [disabled]="disabled">
        <i class="fa-solid fa-calendar"></i>
      </button>
    </div>
    <div class="invalid-feedback">{{invalidFeedback}}</div>
  </div>
</div>
