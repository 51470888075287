import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { ScheduleStatusEnum } from 'src/app/models/Enums';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-update-schedule-details',
  templateUrl: './update-schedule-details.component.html'
})
export class UpdateScheduleDetailsComponent implements OnInit {
  isSubmitted: boolean;

  constructor(private scheduleService: ScheduleService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const partitionkey = this.route.snapshot.paramMap.get('partitionkey');
    const sortKey = this.route.snapshot.paramMap.get('sortKey');
    if (partitionkey && sortKey) {
      this.getScheduleRequest(partitionkey, sortKey);
      return;
    }
    this.router.navigate(['/home']);
  }

  public get request(): ScheduleRequest {
    return this.scheduleService.UIModel;
  }
  public set request(value: ScheduleRequest) {
    this.scheduleService.UIModel = value;
  }

  public async getScheduleRequest(partitionKey: string, sortKey: string) {
    await this.scheduleService.getScheduleRequest(partitionKey, sortKey);
  }

  public async onUpdate(updateRegistrationForm: NgForm) {
    this.isSubmitted = true;
    if (updateRegistrationForm.valid) {
      await this.scheduleService.updateScheduleStatus(ScheduleStatusEnum[this.scheduleService.UIModel.Status],
        this.scheduleService.UIModel);
      this.router.navigate(['/admin-schedule-dashboard']);
    }
  }
  public get showTemplate(): boolean {
    return !!(this.request && this.request.TeamMember && this.request.Status && this.request.TeamMember.TMCommonId);
  }
}
