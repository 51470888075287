import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-date-picker-form-group',
  templateUrl: './date-picker-form-group.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DatePickerFormGroupComponent implements OnInit {
  @Input() public label: string;
  @Input() public key: string;
  @Input() public value: Date;
  @Input() public invalidFeedback: string;
  @Input() public required: boolean;
  @Input() public disabled: boolean;
  @Input() public isInvalid: boolean;
  @Output() public valueChange = new EventEmitter<Date>();

  public minDate: NgbDateStruct;

  ngOnInit() {
    const today = new Date();
    this.minDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate() + 1);
  }

  public modelChange() {
    if (this.value != null && !(this.value instanceof Date)) {
      this.isInvalid = true;
      this.invalidFeedback = 'Please enter date in YYYY-MM-DD format';
    } else if (this.value != null && this.value < new Date()) {
      this.isInvalid = true;
      this.invalidFeedback = 'Date should be future date';
    } else {
      this.isInvalid = false;
    }
    this.valueChange.emit(this.value);
  }
}
