import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAuthService } from './auth-service.interface';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(@Inject('PawsAuthService') private authService: IAuthService) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.loginWithRedirect(environment.auth.connection, state.url);
        }

        return isAuthenticated;
      })
    );
  }
}
