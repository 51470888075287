import { Component, OnDestroy } from '@angular/core';
import { RegistrationRequestSearchService } from './services/search/registration-request-search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationService } from './services/registration/registration.service';
import { Subscription } from 'rxjs';
import { CurrentUserService } from './services/current-user/current-user.service';
import { ScheduleService } from './services/schedule/schedule.service';
import { ScheduleRequestSearchService } from './services/search/schedule-request-search.service';
import { AdminService } from './services/admin/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  isLoadingSubscriptions: Subscription[];

  constructor(
    private spinnerService: NgxSpinnerService,
    private registrationRequestSearchService: RegistrationRequestSearchService,
    private registrationService: RegistrationService,
    private scheduleService: ScheduleService,
    private scheduleRequestSearchService: ScheduleRequestSearchService,
    private currentUserService: CurrentUserService,
    private adminService: AdminService
  ) {
    this.setIsLoadingSubscriptions();
  }

  private setIsLoadingSubscriptions(): void {
    this.isLoadingSubscriptions = [];
    this.isLoadingSubscriptions.push(this.registrationRequestSearchService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
    this.isLoadingSubscriptions.push(this.registrationService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
    this.isLoadingSubscriptions.push(this.scheduleRequestSearchService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
    this.isLoadingSubscriptions.push(this.scheduleService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
    this.isLoadingSubscriptions.push(this.currentUserService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
    this.isLoadingSubscriptions.push(this.adminService.isLoading$.subscribe(() => {
      this.toggleSpinner();
    }));
  }

  private toggleSpinner(): void {
    if (this.registrationService.isLoading || this.registrationRequestSearchService.isLoading
      || this.currentUserService.isLoading || this.scheduleRequestSearchService.isLoading
      || this.scheduleService.isLoading || this.adminService.isLoading) {
      this.spinnerService.show();
    } else {
      this.spinnerService.hide();
    }
  }
  ngOnDestroy() {
    this.isLoadingSubscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.isLoadingSubscriptions = [];
  }
}
