import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RequestTypeEnum, UserTypeEnum } from 'src/app/models/Enums';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect-to-update-detail',
  templateUrl: './redirect-to-update-detail.component.html'
})
export class RedirectToUpdateDetailComponent implements ICellRendererAngularComp {
  public params: any;
  public isRegistrationRequest: boolean;
  public isScheuleRequest: boolean;

  constructor(private router: Router) { }

  agInit(params: any) {
    this.params = params;
    this.isRegistrationRequest = this.params.data.RequestType === RequestTypeEnum.RegistrationRequest;
    this.isScheuleRequest = this.params.data.RequestType === RequestTypeEnum.ScheduleRequest;
  }
  refresh(): boolean {
    return false;
  }
  public get currentUserType(): string {
    if (this.router.url.includes('admin-request-dashboard')) {
      return UserTypeEnum.PulseAdmin;
    }
    if (this.router.url.includes('tl-dashboard')) {
      return UserTypeEnum.TeamLeader;
    }
  }
}
