<app-jumbotron header="Application Details" message="Review and Update the Application Requests!">
</app-jumbotron>
<div class="container" *ngIf="showTemplate">
  <form #updateRegistrationForm="ngForm" [class.was-validated]="isSubmitted">
    <app-tm-autopopulate [teamMember]="request.TeamMember" [createDate]="request.CreateDate">
    </app-tm-autopopulate>
    <app-dog-information [disabled]='true'></app-dog-information>
    <app-document-download></app-document-download>
    <app-tm-acknowledgment [disabled]='true'></app-tm-acknowledgment>
    <app-pulse-admin-section *ngIf="showPulseAdminApprovalForm" [request]="request"></app-pulse-admin-section>
    <app-tl-approval-section *ngIf="showTeamLeaderApprovalForm" [request]="request"></app-tl-approval-section>
    <button class="btn btn-primary" id="btnSave" name="btnSave" (click)="onUpdate(updateRegistrationForm)">Click To
      Save</button>
    <button class="btn btn-secondary ml-1" id="btnCancel" name="btnCancel" [routerLink]="redirectRouteValue">Go To
      Dashboard</button>
  </form>
</div>
