import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDropDownData } from 'src/app/models/common';
import { ControlContainer, NgForm } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { BuildingFloor } from 'src/app/models/location';
import { filter } from 'lodash';

@Component({
  selector: 'app-building-floor-select',
  templateUrl: './building-floor-section.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class BuildingFloorSectionComponent implements OnInit {

  @Input() public isRequired: boolean;
  @Input() public buildingValidationText: string;
  @Input() public floorValidationText: string;
  @Input() public building: string;
  @Input() public floor: string;
  @Output() public buildingChange = new EventEmitter();
  @Output() public floorChange = new EventEmitter();
  public buildings: IDropDownData[];
  public floors: string[];
  private buildingFloorsList: BuildingFloor[];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.buildings = [];
    this.floors = [];
    this.onBuildingChange();
  }

  public async getlocationData() {
    await this.adminService.getBuildingFloor();
    this.buildingFloorsList = this.adminService.LocationData;
    this.buildings = [];
    this.buildingFloorsList.forEach((item) => {
      this.buildings.push({ key: item.Building, value: item.Building });
    });
  }

  public async onBuildingChange() {
    this.floors = [];

    if (!this.buildingFloorsList) {
      await this.getlocationData();
    }

    if (this.building && this.buildingFloorsList) {
      const selectedBuilding = filter(this.buildingFloorsList, { Building: this.building });
      if (selectedBuilding) {
        for (const key of Object.keys(selectedBuilding[0].Floor)) {
          this.floors.push(key);
        }
        this.floors.sort((n1, n2) => parseInt(n1, 10) > parseInt(n2, 10) ? 1 : -1);
        this.buildingChange.emit(this.building);
      }
    }

    // reset the floor value if its not in the floor list
    if (this.floors.filter(x => x === this.floor).length === 0) {
      this.floor = null;
    }
  }

  public onFloorChange() {
    this.floorChange.emit(this.floor);
  }
}
