<app-jumbotron header="Admin Schedule Requests Dashboard">
</app-jumbotron>
<div class="container" *ngIf="searchParams">
  <form>
    <div class="row">
      <div class="col-lg-3 form-group">
        <label for="TMFirstName">First Name</label>
        <input type="text" id="TMFirstName" name="TMFirstName" class="form-control"
          [(ngModel)]="searchParams.TMFirstName" />
      </div>
      <div class="col-lg-3 form-group">
        <label for="TMLastName">Last Name</label>
        <input type="text" id="TMLastName" name="TMLastName" class="form-control"
          [(ngModel)]="searchParams.TMLastName" />
      </div>
      <div class="col-lg-3 form-group">
        <label for="SubTeam">Team</label>
        <input type="text" id="SubTeam" name="SubTeam" class="form-control" [(ngModel)]="searchParams.TMSubTeam" />
      </div>
      <div class="col-lg-3 form-group">
        <label for="TMCompany">Company</label>
        <input type="text" id="TMCompany" name="TMCompany" class="form-control" [(ngModel)]="searchParams.TMCompany" />
      </div>
      <div class="col-lg-3 form-group">
        <label for="schedule-status">Status</label>
        <select id="schedule-status" class="form-control" name="schedule-status" [(ngModel)]="searchParams.Status">
          <option [ngValue]="option.key" *ngFor="let option of statusOptions">{{option.value}}</option>
        </select>
      </div>
      <app-date-picker-form-group class="col-lg-3" label="Schedule Date" key="scheduleDate"
        [(value)]="searchParams.ScheduleStartDate">
      </app-date-picker-form-group>
      <app-building-floor-select class="col-lg-6" [(building)]="searchParams.Location" [(floor)]="searchParams.TMFloor"
        [isRequired]="false">
      </app-building-floor-select>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary mr-1" type="submit" (click)="applyFilters()" id="ApplyFilter">Apply
        Filter</button>
      <button class="btn btn-secondary" (click)="resetFilters()" id="ClearFilter">Clear Filter</button>
    </div>
  </form>
  <div class="mt-4">
    <app-grid paginationPageSize="10" [columnDefs]="scheduleRequestGridColumnDefs" [rowData]="scheduleRequests"
      noRowsMessage="Sorry. No schedule requests were found for the filtered conditions."
      [frameworkComponents]="scheduleRequestGridFrameworkComponents">
    </app-grid>
  </div>
</div>
