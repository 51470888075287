import { Component, OnInit, Inject } from '@angular/core';
import { RegistrationRequest } from 'src/app/models/registrationRequest';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { ScheduleRequestSearchService } from 'src/app/services/search/schedule-request-search.service';
import { RegistrationRequestSearchService } from 'src/app/services/search/registration-request-search.service';
import { ApplicationStatusEnumDic, ApplicationStatusEnum, ScheduleStatusEnum, ScheduleStatusEnumDic } from 'src/app/models/Enums';

@Component({
  selector: 'app-tm-dashboard',
  templateUrl: './tm-dashboard.component.html',
  styleUrls: ['./tm-dashboard.component.scss']
})
export class TmDashboardComponent implements OnInit {
  private apiEndpoint = '/api/searchschedule';
  constructor(
    private registrationService: RegistrationService,
    @Inject('Registration') private registrationRequestSearchService: RegistrationRequestSearchService,
    @Inject('Schedule') private scheduleRequestSearchService: ScheduleRequestSearchService,
    private currentUserService: CurrentUserService,
    private scheduleService: ScheduleService
  ) {
  }

  ngOnInit() {
    this.registrationRequestSearchService.loadCurrentUserRegistrationRequests();
    this.loadScheduleRequests();
  }

  public get registrationRequests(): RegistrationRequest[] {
    return this.registrationRequestSearchService.data;
  }

  public loadScheduleRequests(): void {
    const endDt = new Date();
    endDt.setFullYear(endDt.getFullYear() + 1);
    this.scheduleRequestSearchService.searchParams = {
      TMCommonId: this.currentUserService.teamMember.TMCommonId,
      ScheduleStartDate: new Date(),
      ScheduleEndDate: endDt
    };
    this.scheduleRequestSearchService.loadScheduleRequestsBySearchParams(this.apiEndpoint);
  }

  public get scheduleRequests(): ScheduleRequest[] {
    return this.scheduleRequestSearchService.data;
  }

  public async onRegistrationCancel(cancelItem: RegistrationRequest) {
    await this.registrationService.cancelRegistration(cancelItem);
    this.registrationRequestSearchService.loadCurrentUserRegistrationRequests();
  }

  public async onScheduleCancel(cancelItem: ScheduleRequest) {
    await this.scheduleService.cancelScheduleRequest(cancelItem);
    this.loadScheduleRequests();
  }
}
