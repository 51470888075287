import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UserNotificationService } from 'src/app/services/user-notification/user-notification.service';
import { NotificationMessage } from 'src/app/models/notification';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class UserNotificationComponent implements OnInit {
  constructor(private notificationService: UserNotificationService) {
  }

  ngOnInit() {
  }

  public get notifications(): NotificationMessage[] {
    return this.notificationService.userNotifications;
  }

  public clearMessage(messageId: string): void {
    this.notificationService.clear(messageId);
  }
}
