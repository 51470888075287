import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomePageComponent implements OnInit {
  public imageCycleTime = 5;

  constructor() {
  }

  ngOnInit(): void {
  }

  public get images() {
    return [
      '/assets/TMs/Paws-20220630-007.png',
      '/assets/TMs/Paws-20220629-045.png',
      '/assets/TMs/Paws-20220629-032.png',
      '/assets/TMs/Paws-20220629-010.png',
      '/assets/TMs/Paws-20220630-023.png'
    ];
  }
}

