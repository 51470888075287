<app-jumbotron header="Apply for your dog to join us!"
  message="Your family is our family, and we want you to be able to show off your furry loved ones!">
</app-jumbotron>
<div class="container" *ngIf="request">
  <form #registrationForm="ngForm" [class.was-validated]="isSubmitted">
    <div class="form-group">
      <h3>Paws at Work Application</h3>
      <p>
        Your family is our family and we want you to show off not only the humans
        that are a part of your life, but your canines too!
      </p>
    </div>
    <app-tm-autopopulate [teamMember]="request.TeamMember" [createDate]="request.CreateDate"></app-tm-autopopulate>
    <app-dog-information></app-dog-information>
    <app-dog-vaccineupload></app-dog-vaccineupload>
    <app-tm-acknowledgment></app-tm-acknowledgment>
    <button class="btn btn-primary" id="btnSubmit" name="btnSubmit" (click)="onSubmit(registrationForm)">Submit</button>
    <button class="btn btn-secondary ml-1" id="btnCancel" name="btnCancel" routerLink="/home">Cancel</button>
  </form>
</div>
