<mat-dialog-content>
  <p>Important! Due to the building infrastructure in One Campus Martius, the dedicated Paws At Work desks have been modified to prevent building damage and minimize allergens.</p>

  <p>By clicking I Agree, you are confirming that you and your dog will sit at a <a target="_blank" href="https://rockworld.foc.zone/rockworld?id=kb_article&sysparm_article=KB0013075#mcetoc_1gnks17ka6">dedicated Paws At Work desk</a> on your floor in One Campus Martius. If you fail to do so, you will be removed from the program.</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button class="btn btn-primary mr-1" [mat-dialog-close]="true">I agree</button>
  <button mat-button class="btn btn-secondary" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
