<fieldset ngModelGroup="teammemberdata">
  <legend class="h4">Your Human Information (auto-PUP-ulated)</legend>
  <div class="form-row">
    <div class="form-group col-lg-3">
      <label for="ApplicationDate">Today's Date</label>
      <input class="form-control" id="appDate" name="appDate" value="{{createDate| date:'yyyy-MM-dd'}}" disabled />
    </div>
    <div class="form-group col-lg-3">
      <label>Name</label>
      <input type="text" id="TMName" value="{{teamMember.TMName}}" disabled class="form-control" name="TMName" />
    </div>
    <div class="form-group col-lg-3">
      <label>Extension</label>
      <input type="text" id="TMExtension" value="{{teamMember.TMExtension}}" disabled class="form-control"
        name="TMExtension" />
    </div>
    <div class="form-group col-lg-3">
      <label>Job Title</label>
      <input type="text" id="TMJobTitle" value="{{teamMember.TMJobTitle}}" disabled class="form-control"
        name="TMJobTitle" />
    </div>
    <div class="form-group col-lg-3">
      <label>Business Area</label>
      <input type="text" id="TMTeam" value="{{teamMember.TeamName}}" disabled class="form-control" name="TMTeam" />
    </div>
    <div class="form-group col-lg-3">
      <label>Team</label>
      <input type="text" id="TMSubTeam" value="{{teamMember.TMSubTeam}}" disabled class="form-control"
        name="TMSubTeam" />
    </div>
    <div class="form-group col-lg-3">
      <label>Company</label>
      <input type="text" id="TMCompany" value="{{teamMember.TMCompany}}" disabled class="form-control"
        name="TMCompany" />
    </div>
    <div class="form-group col-lg-3">
      <label>Team Leader</label>
      <input type="text" id="TeamLeader" class="form-control" name="TeamLeader" [(ngModel)]="teamMember.TeamLeaderName"
        disabled class="form-control" />
    </div>
    <app-building-floor-select class="col-lg-6" [(building)]="teamMember.Location" [(floor)]="teamMember.TMFloor"
      [isRequired]="true" buildingValidationText="Please select your building."
      floorValidationText="Please select your floor.">
    </app-building-floor-select>
  </div>
</fieldset>