import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduleStatusEnum, ScheduleStatusEnumDic } from 'src/app/models/Enums';
import { IHttpResponse } from 'src/app/models/httpResponse';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { TeamMember } from 'src/app/models/teamMember';
import { IUniqueIdentifier } from 'src/app/models/uniqueIdentifier';
import { IUpdateRequest } from 'src/app/models/UpdateRequest';
import { environment } from 'src/environments/environment';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IScheduleService } from './scheduleService.interface';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService implements IScheduleService {
  public DBModel: ScheduleRequest;
  public UIModel: ScheduleRequest;
  private _isLoading: BehaviorSubject<boolean>;
  public isLoading$: Observable<boolean>;

  constructor(@Inject('PawsApiService') private apiService: IPawsApiService,
    private notificationService: UserNotificationService,
    private datePipe: DatePipe) {
    this._isLoading = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this._isLoading.asObservable();
  }

  public get isLoading(): boolean {
    return this._isLoading.value;
  }
  private clearModels(): void {
    this.DBModel = null;
    this.UIModel = null;
  }
  public buildNewScheduleRequest(teamMember: TeamMember): void {
    const teamMemberCopy = Object.assign(new TeamMember(), teamMember);
    this.assignScheduleRequest(new ScheduleRequest(teamMemberCopy));
  }

  public saveScheduleRequest(): Observable<IHttpResponse> {
    return this.apiService.post<ScheduleRequest>(environment.api.pawsApi.url + '/api/saveschedule', this.UIModel);
  }

  public async cancelScheduleRequest(cancelItem: ScheduleRequest) {
    await this.updateScheduleStatus(ScheduleStatusEnum.CancelledByTeamMember, cancelItem);
  }

  public async updateScheduleStatus(newStatus: ScheduleStatusEnum, itemToUpdate: ScheduleRequest) {
    try {
      this._isLoading.next(true);
      const updateParams: IUpdateRequest = {
        PartitionKey: itemToUpdate.PartitionKey,
        SortKey: itemToUpdate.SortKey,
        Status: itemToUpdate.Status,
        ReasonforDenial: itemToUpdate.ReasonforDenial,
        NewStatus: newStatus,
        LastUpdateBy: itemToUpdate.TeamMember.TMCommonId,
        TMFloor: itemToUpdate.TeamMember.TMFloor,
        TMLocation: itemToUpdate.TeamMember.Location,
        Seating: itemToUpdate.seating,
        TeamMemberAssignment: itemToUpdate.teamMemberAssignment
      };
      await this.apiService.put<IUpdateRequest>(environment.api.pawsApi.url + '/api/updateScheduleStatus', updateParams).toPromise();
      this.notificationService.addSuccessMessage('Schedule Request status was updated successfully.');
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
  public async getScheduleRequest(partitionKey: string, sortKey: string) {
    try {
      this.clearModels();
      this._isLoading.next(true);
      const params: IUniqueIdentifier = {
        PartitionKey: partitionKey,
        SortKey: sortKey
      };
      const scheduleRequest = await this.apiService.get<ScheduleRequest, IUniqueIdentifier>(
        environment.api.pawsApi.url + '/api/getschedule', params)
        .pipe(
          map(response => {
            const request = Object.assign(new ScheduleRequest(), response);
            request.CreateDate = new Date(request.CreateDate);
            request.scheduleDate = new Date(this.datePipe.transform(request.scheduleDate, 'MM/dd/yyyy', 'UTC'));
            request.TeamMember = Object.assign(new TeamMember(), response.TeamMember);
            request.hasOffice = !!response.hasOffice;
            request.Status = ScheduleStatusEnumDic[request.Status];
            request.isApprovedUser = 'Yes'; // setting default value to show schedule info to during update detail for pulse admin
            return request;
          })
        ).toPromise();
      this.assignScheduleRequest(scheduleRequest);
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
  private assignScheduleRequest(scheduleRequest: ScheduleRequest): void {
    this.DBModel = scheduleRequest;
    this.UIModel = Object.assign(new ScheduleRequest(), this.DBModel);
  }
}
