import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAuthService } from './auth-service.interface';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(@Inject('PawsAuthService') private authService: IAuthService) {

  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      map((user: User) => {
        if (!user) {
          this.authService.loginWithRedirect(environment.auth.connection, state.url);
        } else {
          this.authService.loadUserInformation(user);
        }
        return true;
      })
    );
  }
}
