export enum ApplicationStatusEnum {
    PendingPulseApproval = 'PendingPulseApproval',
    ApprovedByPulse = 'ApprovedByPulse',
    PendingTeamLeaderApproval = 'PendingTeamLeaderApproval',
    ApprovedByTeamLeader = 'ApprovedByTeamLeader',
    ApprovedByTeamLeaderEmailSent = 'ApprovedByTeamLeaderEmailSent',
    CancelledByTeamMember = 'CancelledByTeamMember',
    CancelledByTeamMemberEmailSent = 'CancelledByTeamMemberEmailSent',
    DeniedByPulse = 'DeniedByPulse',
    DeniedByPulseEmailSent = 'DeniedByPulseEmailSent',
    DeniedByTeamLeader = 'DeniedByTeamLeader',
    DeniedByTeamLeaderEmailSent = 'DeniedByTeamLeaderEmailSent',
    ExpiredDocuments = 'ExpiredDocuments'
}

export enum DeskSelectionEnum {
    YourOffice = 'Your Office',
    PawsDeskA = 'Paws Desk A',
    PawsDeskB = 'Paws Desk B'
}

export enum TeamMemberAssignmentEnum {
  TeamMemberOne = 'Team Member 1',
  TeamMemberTwo = 'Team Member 2'
}

export enum ScheduleStatusEnum {
    PendingPulseApproval = 'PendingPulseApproval',
    ApprovedByPulse = 'ApprovedByPulse',
    ApprovalEmailSent = 'ApprovalEmailSent',
    DenialEmailSent = 'DenialEmailSent',
    DeniedByPulse = 'DeniedByPulse',
    CancelledByTeamMember = 'CancelledByTeamMember',
    CancelEmailSent = 'CancelEmailSent'
}

export enum RequestTypeEnum {
    RegistrationRequest = 'RegistrationRequest',
    PawsAdmin = 'PawsAdmin',
    StatusChangeHistory = 'StatusChangeHistory',
    ScheduleRequest = 'ScheduleRequest',
}

type EnumDictionary<T extends string | symbol | number, V> = {
    [K in T]: V;
};

export const ApplicationStatusEnumDic: EnumDictionary<ApplicationStatusEnum, string> = {
    [ApplicationStatusEnum.PendingPulseApproval]: 'Pending Pulse Approval',
    [ApplicationStatusEnum.ApprovedByPulse]: 'Approved By Pulse',
    [ApplicationStatusEnum.PendingTeamLeaderApproval]: 'Pending Team Leader Approval',
    [ApplicationStatusEnum.ApprovedByTeamLeader]: 'Approved By Team Leader',
    [ApplicationStatusEnum.ApprovedByTeamLeaderEmailSent]: 'Approved By Team Leader Email Sent',
    [ApplicationStatusEnum.CancelledByTeamMember]: 'Cancelled By Team Member',
    [ApplicationStatusEnum.CancelledByTeamMemberEmailSent]: 'Cancelled By Team Member Email Sent',
    [ApplicationStatusEnum.DeniedByPulse]: 'Denied By Pulse',
    [ApplicationStatusEnum.DeniedByPulseEmailSent]: 'Denied By Pulse Email Sent',
    [ApplicationStatusEnum.DeniedByTeamLeader]: 'Denied By Team Leader',
    [ApplicationStatusEnum.DeniedByTeamLeaderEmailSent]: 'Denied By Team Leader Email Sent',
    [ApplicationStatusEnum.ExpiredDocuments]: 'Expired Documents'
};

export const ScheduleStatusEnumDic: EnumDictionary<ScheduleStatusEnum, string> = {
    [ScheduleStatusEnum.PendingPulseApproval]: 'Pending Pulse Approval',
    [ScheduleStatusEnum.ApprovedByPulse]: 'Approved By Pulse',
    [ScheduleStatusEnum.ApprovalEmailSent]: 'Approval Email Sent',
    [ScheduleStatusEnum.DeniedByPulse]: 'Denied By Pulse',
    [ScheduleStatusEnum.DenialEmailSent]: 'Denial Email Sent',
    [ScheduleStatusEnum.CancelledByTeamMember]: 'Cancelled By Team Member',
    [ScheduleStatusEnum.CancelEmailSent]: 'Cancelled Email Sent'
};
export enum UserTypeEnum {
    PulseAdmin = 'PulseAdmin',
    TeamLeader = 'TeamLeader',
    TeamMember = 'TeamMember'
}
