import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormBase, IDropDownData } from 'src/app/models/common';
import {
  ApplicationStatusEnum,
  ApplicationStatusEnumDic,
  DeskSelectionEnum,
  RequestTypeEnum,
  ScheduleStatusEnum,
  ScheduleStatusEnumDic,
  TeamMemberAssignmentEnum
} from 'src/app/models/Enums';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import {
  ScheduleRequestSearchService
} from 'src/app/services/search/schedule-request-search.service';

@Component({
  selector: 'app-pulse-admin-section',
  templateUrl: './pulse-admin-section.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PulseAdminSectionComponent implements OnInit {

  constructor(private scheduleRequestSearchService: ScheduleRequestSearchService) { }

  @Input() public request: FormBase;

  initialStatus: string;
  newStatus: string;
  deskSelection: string;
  assignmentSelection: string;
  canBeApprovedByPulse = false;
  statusOptions: IDropDownData[];
  deskOptions: IDropDownData[];
  assignmentOptions: IDropDownData[];
  isScheduleRequest: boolean;

  ngOnInit() {
    this.initialStatus = this.request.Status;
    this.checkForAvailableAssignments();
    this.isScheduleRequest = this.request.RequestType === RequestTypeEnum.ScheduleRequest;
  }
  public get showReasonForDenial(): boolean {
    return (
      this.request &&
      (this.request.Status === ApplicationStatusEnum.DeniedByPulse ||
        this.request.Status === ScheduleStatusEnum.DeniedByPulse)
    );
  }
  populateAdminActionDropDown() {
    this.statusOptions = [];
    if (this.request.RequestType === RequestTypeEnum.RegistrationRequest) {
      this.addApplicationStatus(ApplicationStatusEnum.PendingPulseApproval);
      this.addApplicationStatus(ApplicationStatusEnum.ApprovedByPulse);
      this.addApplicationStatus(ApplicationStatusEnum.DeniedByPulse);
      this.addApplicationStatus(
        ApplicationStatusEnum.PendingTeamLeaderApproval
      );
      this.addApplicationStatus(ApplicationStatusEnum.ApprovedByTeamLeader);
      this.addApplicationStatus(ApplicationStatusEnum.DeniedByTeamLeader);
      this.addApplicationStatus(ApplicationStatusEnum.ExpiredDocuments);
      return;
    }
    if (this.request.RequestType === RequestTypeEnum.ScheduleRequest) {
      if (this.canBeApprovedByPulse) {
        this.addScheduleStatus(ScheduleStatusEnum.ApprovedByPulse);
      }
      this.addScheduleStatus(ScheduleStatusEnum.DeniedByPulse);
      this.addScheduleStatus(ScheduleStatusEnum.PendingPulseApproval);
      return;
    }
  }

  populateAssignmentOptions(validAssignmentOption?: TeamMemberAssignmentEnum) {
    this.assignmentOptions = [];

    if (validAssignmentOption) {
      this.addAssignmentOption(validAssignmentOption);
    } else {
      this.addAssignmentOption(TeamMemberAssignmentEnum.TeamMemberOne);
      this.addAssignmentOption(TeamMemberAssignmentEnum.TeamMemberTwo);
    }
  }

  private addAssignmentOption(val: TeamMemberAssignmentEnum): void {
    const assignmentOptionToAdd: IDropDownData = { key: val, value: val };
    this.assignmentOptions.push(assignmentOptionToAdd);
  }

  async checkForAvailableAssignments() {
    const existingSchedules = await this.checkForExistingSchedules();

    if (existingSchedules.length >= 2) {
      this.canBeApprovedByPulse = false;
      this.populateAdminActionDropDown();
    } else if (existingSchedules.length === 1 && existingSchedules[0].teamMemberAssignment) {
      this.canBeApprovedByPulse = true;
      if (existingSchedules[0].teamMemberAssignment === TeamMemberAssignmentEnum.TeamMemberOne) {
        this.populateAssignmentOptions(
          TeamMemberAssignmentEnum.TeamMemberTwo
        );
      } else {
        this.populateAssignmentOptions(
          TeamMemberAssignmentEnum.TeamMemberOne
        );
      }
      this.populateAdminActionDropDown();
    } else {
      this.canBeApprovedByPulse = true;
      this.populateAssignmentOptions();
      this.populateAdminActionDropDown();
    }
  }

  private addApplicationStatus(val: ApplicationStatusEnum): void {
    const returnValue: IDropDownData = {
      key: val,
      value: ApplicationStatusEnumDic[val],
    };
    this.statusOptions.push(returnValue);
  }

  private addScheduleStatus(val: ScheduleStatusEnum): void {
    const returnValue: IDropDownData = {
      key: val,
      value: ScheduleStatusEnumDic[val],
    };
    this.statusOptions.push(returnValue);
  }

  // Not being used for now(8/8/2022), will be used in future for buildings with dedicated paws desks
  // async checkForAvailableSeating() {
  //   const existingSchedules = await this.checkForExistingSchedules();

  //   if (existingSchedules.length >= 2) {
  //     this.canBeApprovedByPulse = true;
  //     this.populateAdminActionDropDown();
  //   } else if (existingSchedules.length === 1
  //     && existingSchedules[0].seating
  //     && existingSchedules[0].seating !== DeskSelectionEnum.YourOffice) {
  //     this.populateDeskOptions(
  //       existingSchedules[0].seating === DeskSelectionEnum.PawsDeskA
  //         ? DeskSelectionEnum.PawsDeskB
  //         : DeskSelectionEnum.PawsDeskA
  //     );
  //     this.populateAdminActionDropDown();
  //   } else {
  //     this.populateDeskOptions();
  //     this.populateAdminActionDropDown();
  //   }
  // }

  // Not being used for now(8/8/2022), will be used in future for buildings with dedicated paws desks
  // populateDeskOptions(validDeskOption?: DeskSelectionEnum) {
  //   this.deskOptions = [];
  //   if (this.request.hasOffice) {
  //     this.addSeatingOption(DeskSelectionEnum.YourOffice);
  //   }

  //   if (validDeskOption) {
  //     this.addSeatingOption(validDeskOption);
  //   } else {
  //     this.addSeatingOption(DeskSelectionEnum.PawsDeskA);
  //     this.addSeatingOption(DeskSelectionEnum.PawsDeskB);
  //   }
  // }

  // Not being used for now(8/8/2022), will be used in future for buildings with dedicated paws desks
  // private addSeatingOption(val: DeskSelectionEnum): void {
  //   const deskOptionToAdd: IDropDownData = { key: val, value: val };
  //   this.deskOptions.push(deskOptionToAdd);
  // }

  async checkForExistingSchedules(): Promise<any> {
    let existingSchedules: ScheduleRequest[];
    this.scheduleRequestSearchService.searchParams = {
      TMFloor: this.request.TeamMember.TMFloor,
      Location: this.request.TeamMember.Location,
      ScheduleStartDate: this.request.scheduleDate
    };

    await this.scheduleRequestSearchService.loadScheduleRequestsBySearchParams('/api/searchschedule').then(
      () =>
        existingSchedules = this.scheduleRequestSearchService._data.filter(
          schedule =>
            (schedule.Status === ScheduleStatusEnumDic.ApprovalEmailSent || schedule.Status === ScheduleStatusEnumDic.ApprovedByPulse)
            && (schedule.SortKey !== this.request.SortKey || schedule.PartitionKey !== this.request.PartitionKey)
        )
    );
    return existingSchedules;
  }

  deniedByPulse(status: ScheduleStatusEnum): boolean {
    return status === ScheduleStatusEnum.DeniedByPulse;
  }

  public get showTemplate(): boolean {
    return !!(this.request && this.request.Status);
  }
}
