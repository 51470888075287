import { Component, OnInit } from '@angular/core';
import { RktMastheadModule } from '@rocketcentral/rocket-design-system-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    // check if the person is authenticated
  }
}
