import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DocumentUploadComponent implements OnInit {
  @Input() headerText: string;
  @Input() validationText: string;
  @Input() content: object;
  @Output() contentChange = new EventEmitter<object>();
  fileReader: any;
  file: any;
  @Input() public isInvalid: boolean;
  @Output() public isInvalidChange = new EventEmitter<boolean>();

  ngOnInit() {
    this.fileReader = new FileReader();
  }

  onFileChange(event: any) {

    if (!event.target.files || event.target.files.length < 1) {
      // kind of a weird case, especially if a user adds multiple files, might just go into a broken state? though, the
      // picker doesn't appear to allow multiselect. You can definitely hit this statement if you browser for a file and
      // then cancel
      return;
    }

    this.validationText = '';
    this.isInvalid = false;

    this.file = event.target.files[0]; // by default one file selection is allowed, so we'll just pick the first one
    const greaterThan2MB = this.file.size > 2 * 1024 * 1000;
    const inUnacceptedFileFormat = !/(\.docx|\.doc|\.txt|\.rtf|\.odt|\.pdf|\.xls|\.csv|\.html|\.jpg|\.png|\.jpeg|\.heif|\.heic|\.xlsx)$/i.
      exec(this.file.name);

    if (greaterThan2MB) {
      this.isInvalid = true;
      this.validationText = 'This file is too large. Please compress the file to smaller than 2MB.';
    } else if (inUnacceptedFileFormat) {
      this.isInvalid = true;
      this.validationText = 'Please ensure the document is an accepted format';
    }

    this.isInvalidChange.emit(this.isInvalid);

    if (this.isInvalid) {
      return;
    }

    // only do this when success
    this.fileReader.onload = this.onFileReaderLoad;
    this.fileReader.readAsDataURL(this.file);
  }
  onFileReaderLoad = () => {
    this.contentChange.emit({ type: this.file.type, content: this.fileReader.result.split(',')[1], name: this.file.name });
  };
}
