<fieldset ngModelGroup="doginformation">
  <legend class="h4">Your Canine's Information</legend>
  <div class="form-row">
    <div class="form-group col-lg-3">
      <label for="DogName" class="required-field">Name</label>
      <input required id="DogName" name="DogName" class="form-control" #dogNameControl="ngModel"
        [(ngModel)]="request.DogName" [disabled]="disabled" (change)="request.DogName=request.DogName.trim()" />
      <div class="invalid-feedback">
        Please enter your dog's name.
      </div>
    </div>
    <div class="form-group col-lg-3">
      <label for="DogBreed" class="required-field">Breed</label>
      <input required id="DogBreed" name="DogBreed" class="form-control" #dogBreedControl="ngModel"
        [(ngModel)]="request.DogBreed" [disabled]="disabled" (change)="request.DogBreed=request.DogBreed.trim()" />
      <div class="invalid-feedback">
        Please enter your dog's breed.
      </div>
    </div>
    <div class="form-group col-lg-3">
      <label>Weight (approx)</label>
      <input type="text" id="DogWeight" class="form-control" name="DogWeight" [(ngModel)]="request.DogWeight"
        [disabled]="disabled" />
    </div>
    <div class="form-group col-lg-3">
      <label>Age (approx)</label>
      <input type="text" id="DogAge" class="form-control" name="DogAge" pattern="^[0-9]*$" [(ngModel)]="request.DogAge"
        [disabled]="disabled" />
      <div class="invalid-feedback">
        Please enter age only in number.
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-lg-9">
      <label>Short Description of Dog's Personality (140 characters only)</label>
      <input type="text" id="DogDescription" class="form-control" maxlength="140" name="DogDescription"
        [(ngModel)]="request.DogDescription" [disabled]='disabled' />
    </div>
    <div class="form-group col-lg-3">
      <label>Spayed/Neutered?</label>
      <select id="DogNeutered" name="DogNeutered" class="form-control" #dogNeuteredControl="ngModel"
        [(ngModel)]="request.DogNeutered" [disabled]='disabled'>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <div class="invalid-feedback">
        Please choose Yes or No.
      </div>
    </div>
  </div>
</fieldset>