export class TeamMember {
    public TMCommonId?: string;
    public TMFirstName?: string;
    public TMLastName?: string;
    public get TMName(): string {
        return this.TMFirstName && this.TMLastName ? `${this.TMFirstName} ${this.TMLastName}` : null;
    }
    public TMSignature?: string;
    public TMEmail?: string;
    public TMExtension?: string;
    public TMJobTitle?: string;
    public TeamName?: string;
    public TMSubTeam?: string;
    public TMCompany?: string;
    public Location?: string;
    public TMFloor?: string;
    public TeamLeaderName?: string;
    public TeamLeaderEmail?: string;
    public TeamLeaderCommonId?: string;
}
