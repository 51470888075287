import { Component, OnInit, Input } from '@angular/core';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { NgForm, ControlContainer } from '@angular/forms';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';

@Component({
  selector: 'app-schedule-information',
  templateUrl: './schedule-information.component.html',
  styleUrls: ['./schedule-information.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ScheduleInformationComponent implements OnInit {
  @Input() public disabled: boolean;
  constructor(private scheduleService: ScheduleService) { }

  ngOnInit() {
  }
  public get request(): ScheduleRequest {
    return this.scheduleService.UIModel;
  }
  public get showTemplate(): boolean {
    return !!(this.request && this.request.TeamMember && this.request.TeamMember.TMCommonId);
  }
}
