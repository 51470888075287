<fieldset ngModelGroup="tlApprovalSection">
    <legend class="h4">Team Leader Approval Section</legend>
    <div class="form-row" *ngIf="showTemplate">
        <div class="form-group col-lg-12">
            <p>
              Please <strong>
                <a class="text-primary" href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sys_kb_id=cabd4c58874e1594d5d1ec609bbb35df&spa=1" target="_blank">click here</a>
                </strong> to review the program guidelines before approving or denying this request!
            </p>
        </div>
        <div class="form-group col-lg-12">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" required class="custom-control-input" name="agreeToTermsAndConditions"
                    id="agreeToTermsAndConditions" [(ngModel)]="agreeToTermsAndConditions">
                <label class="custom-control-label" for="agreeToTermsAndConditions">
                    <span class="required-field">I have read and agree to the terms and conditions presented in the Paws
                        at
                        Work policy document</span>
                </label>
            </div>
        </div>
        <div class="form-group col-lg-3">
            <label class="required-field">Current Status</label>
            <input type="text" id="currentStatus" value="{{initialStatus}}" disabled class="form-control"
                name="currentStatus" />
        </div>
        <div class="form-group col-lg-3">
            <label for="applicationStatus" class="required-field">New Status</label>
            <select id="applicationStatus" class="form-control" name="applicationStatus" [(ngModel)]="request.Status"
                required [disabled]="!agreeToTermsAndConditions">
                <option [ngValue]="option.key" *ngFor="let option of statusOptions">{{option.value}}</option>
            </select>
        </div>
        <div *ngIf="showReasonForDenial" class="form-group col-lg-6">
            <label for="reasonForDenial" class="required-field">Reason for Denial</label>
            <textarea required id="reasonForDenial" class="form-control" name="reasonForDenial"
                [(ngModel)]="request.ReasonforDenial"></textarea>
            <div class="invalid-feedback">
                Please enter some reason for denial.
            </div>
        </div>
    </div>
</fieldset>
