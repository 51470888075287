import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  ApplicationStatusEnum, ApplicationStatusEnumDic,
  RequestTypeEnum, ScheduleStatusEnum, ScheduleStatusEnumDic
} from 'src/app/models/Enums';
import { IDropDownData } from 'src/app/models/common';

@Component({
  selector: 'app-status-form-group',
  templateUrl: './status-form-group.component.html'
})
export class StatusFormGroupComponent implements OnInit {
  @Input() public label: string;
  @Input() public key: string;
  @Input() public requestType: RequestTypeEnum;
  @Input() public value: ApplicationStatusEnum;

  @Output() public valueChange = new EventEmitter<ApplicationStatusEnum>();

  public options: IDropDownData[];

  constructor() {
  }

  ngOnInit() {
    this.options = [];
    this.options.push({ key: '', value: 'All' });
    if (!this.requestType || this.requestType === RequestTypeEnum.RegistrationRequest) {
      for (const key of Object.keys(ApplicationStatusEnum)) {
        this.options.push({ key, value: ApplicationStatusEnumDic[key] });
      }
    } else if (this.requestType === RequestTypeEnum.ScheduleRequest) {
      for (const key of Object.keys(ScheduleStatusEnum)) {
        this.options.push({ key, value: ScheduleStatusEnumDic[key] });
      }
    }
  }

  public modelChange() {
    this.valueChange.emit(this.value);
  }
}

