import { Component, OnInit, Inject } from '@angular/core';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DedicatedDeskConfirmComponent } from 'src/app/components/modals/dedicated-desk-confirm/dedicated-desk-confirm.component';
import { UserNotificationService } from 'src/app/services/user-notification/user-notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationMessageType } from 'src/app/models/notification';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html'
})
export class ScheduleFormComponent implements OnInit {
  public today: NgbDateStruct;
  public isSubmitted: boolean;

  constructor(
    public scheduleService: ScheduleService,
    public router: Router,
    public notificationService: UserNotificationService,
    public spinner: NgxSpinnerService,
    public currentUserService: CurrentUserService,
    public dialog: MatDialog
  ) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate() + 1);
  }

  ngOnInit(): void {
    this.setAutoPopulateValues();
  }

  setAutoPopulateValues() {
    if (this.currentUserService.teamMember && this.currentUserService.teamMember.TeamLeaderName) {
      this.scheduleService.buildNewScheduleRequest(this.currentUserService.teamMember);
    } else {
      setTimeout(() => {
        this.setAutoPopulateValues();
      });
    }
  }

  public get request(): ScheduleRequest {
    return this.scheduleService.UIModel;
  }

  public onSubmit(scheduleForm: NgForm): void {
    this.isSubmitted = true;

    if (!scheduleForm.valid) {
      window.scrollTo(0, 0);
      return;
    }

    // check for need for location specific confirmation. Initially TMLocation of "Detroit - One Campus Martius" or "Detroit - OCM"
    const buildingsThatRequireDeskConfirmation = new Set<string>(
      [
        'Detroit - One Campus Martius',
        'Detroit - OCM'
      ]);

    if ( buildingsThatRequireDeskConfirmation.has(scheduleForm.controls['TMLocation'].value) ) {
        this.dialog.open(DedicatedDeskConfirmComponent).afterClosed().subscribe(agreed => {

          if (agreed) {
            this.submitSchedule(scheduleForm);
          }
        });
    } else {
      this.submitSchedule(scheduleForm);
    }
  }

  private submitSchedule(scheduleForm: NgForm): void {
    this.spinner.show();
    this.scheduleService.saveScheduleRequest()
      .subscribe(data => {
        this.spinner.hide();
        if (data) {
          this.notificationService.addMessage('Schedule Request Submitted Successfully', NotificationMessageType.Info);
        } else {
          this.notificationService.addMessage('There has been a problem processing your request.', NotificationMessageType.Error);
        }
        this.router.navigate(['/home']);
      }, error => {
        if (error.status === 400) {
          this.notificationService.addMessage(
            'You cannot have more than one schedule request for the same date or the schedule you set is invalid.',
            NotificationMessageType.Error
          );
        } else {
          this.notificationService.addMessage('There has been a problem processing your request.', NotificationMessageType.Error);
        }
        this.spinner.hide();
        window.scrollTo(0, 0);
      });
  }
}
