import { Component, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    window['launchKitStaticComponentsConfig'] = {
      footerBasicInternal: {
        target: '#footer',
      },
    };

    const scriptId = 'launchKitComponentLoaderScriptFooter';
    if (!document.querySelector(`#${scriptId}`)) {
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'id', scriptId);
      this.renderer.setAttribute(script, 'src', 'https://cdn-internal.webcms.foc.zone/launchkit/components/loader.min.js');
      this.renderer.setAttribute(script, 'data-lksc-environment', 'prod');
      this.renderer.setAttribute(script, 'data-lksc-components', 'footerBasicInternal_RocketEnterpriseTech');
      this.renderer.appendChild(document.body, script);
    }
  }
}
