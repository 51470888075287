<app-jumbotron header="Schedule a time for your dog to join us!"
  message="Your family is our family, and we want you to be able to show off your furry loved ones!">
</app-jumbotron>
<div class="container" *ngIf="request">
  <form #scheduleForm="ngForm" [class.was-validated]="isSubmitted" (ngSubmit)="onSubmit(scheduleForm)">
    <app-tm-autopopulate [teamMember]="request.TeamMember" isSubmitted="false" [createDate]="request.CreateDate">
    </app-tm-autopopulate>
    <app-schedule-information></app-schedule-information>
    <button name="btnSave" class="btn btn-primary mr-1">Submit</button>
    <button name="btnCancel" class="btn btn-secondary" routerLink="/home">Cancel</button>
  </form>
</div>
