import { Injectable, ErrorHandler, NgZone, Injector } from '@angular/core';
import { UserNotificationService } from './../user-notification/user-notification.service';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
    constructor(private userNotificationService: UserNotificationService, private injector: Injector) {
        super();
    }

    public handleError(error: any): void {
        if (!this.userNotificationService.hasErrorNotifications) {
            if (this.isIEOrEdgeBrowser()) {
                const errorMessage = 'You are using a browser which is not supported by PawsAtWork. ' +
                    'Please use Chrome for best site user experience.';
                this.injector.get(NgZone).run(() => this.userNotificationService.addErrorMessage(errorMessage));
            } else {
                this.injector.get(NgZone).run(() => this.userNotificationService.handleError(error));
            }
        }
        super.handleError(error);
    }

    private isIEOrEdgeBrowser() {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }
}
