import { map as lodashMap, orderBy } from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApplicationStatusEnumDic } from 'src/app/models/Enums';
import { RegistrationRequest } from 'src/app/models/registrationRequest';
import { ISearchParams } from 'src/app/models/SearchParams';
import { TeamMember } from 'src/app/models/teamMember';
import { CurrentUserService } from '../current-user/current-user.service';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IRegistrationRequestSearchService } from './registration-request-search.interface';
import { SearchService } from './search.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationRequestSearchService extends SearchService<RegistrationRequest> implements IRegistrationRequestSearchService {
  constructor(
    private currentUserService: CurrentUserService,
    @Inject('PawsApiService') apiService: IPawsApiService,
    notificationService: UserNotificationService
  ) {
    super(apiService, notificationService);
  }

  public async loadCurrentUserRegistrationRequests() {
    this.searchParams = { TMCommonId: this.currentUserService.teamMember.TMCommonId };
    this.loadRegistrationRequestsBySearchParams();
  }

  public async loadRegistrationRequestsBySearchParams() {
    try {
      this._isLoading.next(true);
      this._data = await this.apiService.get<RegistrationRequest[], ISearchParams>(
        environment.api.pawsApi.url + '/api/searchregistration', this.searchParams)
        .pipe(
          map(response => {
            const registrationRequests = lodashMap(response, (item) => {
              const registrationRequest = Object.assign(new RegistrationRequest(), item);
              registrationRequest.TeamMember = Object.assign(new TeamMember(), item.TeamMember);
              registrationRequest.Status = ApplicationStatusEnumDic[registrationRequest.Status];
              return registrationRequest;
            });
            return orderBy(registrationRequests,
              (request) => [request.Status, new Date(request.CreateDate)], ['asc', 'desc']
            );
          })
        ).toPromise();
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
}
