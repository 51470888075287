import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationMessageType } from 'src/app/models/notification';
import { UserNotificationService } from 'src/app/services/user-notification/user-notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationStatusEnum } from '../../models/Enums';
import { RegistrationRequest } from 'src/app/models/registrationRequest';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Component({
  selector: 'app-paws-registration',
  templateUrl: './paws-registration.component.html',
})
export class PawsRegistrationComponent implements OnInit {
  timeout: string;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private notificationService: UserNotificationService,
    private spinner: NgxSpinnerService,
    private currentUserService: CurrentUserService
  ) {
  }

  isSubmitted: boolean;

  ngOnInit(): void {
    this.setAutoPopulateValues();
  }

  public get request(): RegistrationRequest {
    return this.registrationService.UIModel;
  }

  // set the values from ping service
  setAutoPopulateValues() {
    if (this.currentUserService.teamMember && this.currentUserService.teamMember.TeamLeaderName) {
      this.registrationService.buildNewRegistrationRequest(this.currentUserService.teamMember);
      if (!this.registrationService.UIModel.Status) {
        this.registrationService.UIModel.Status = ApplicationStatusEnum[ApplicationStatusEnum.PendingPulseApproval];
      }
    } else {
      setTimeout(() => {
        this.setAutoPopulateValues();
      });
    }
  }

  onSubmit(registrationForm: NgForm) {
    this.isSubmitted = true;
    if (registrationForm.valid && this.registrationService.isUIModelValid) {
      this.spinner.show();
      this.registrationService.submitDogRegistration()
        .subscribe(data => {
          this.spinner.hide();
          if (data) {
            console.log('success response' + JSON.stringify(data));
            this.notificationService.addMessage('Registration Request Submitted Successfully', NotificationMessageType.Info);
          }
          this.router.navigate(['/home']);
        }, error => {
          this.notificationService.addMessage('There has been some problem processing your request.', NotificationMessageType.Error);
          this.spinner.hide();
        });
    } else {
      window.scrollTo(0, 0);
    }
  }
}
