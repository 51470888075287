import { Component, OnInit, Input } from '@angular/core';
import { FormBase, IDropDownData } from 'src/app/models/common';
import { ApplicationStatusEnum, ApplicationStatusEnumDic } from 'src/app/models/Enums';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-tl-approval-section',
  templateUrl: './tl-approval-section.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TlApprovalSectionComponent implements OnInit {

  @Input() public request: FormBase;

  initialStatus: string;
  statusOptions: IDropDownData[];
  agreeToTermsAndConditions: boolean;

  ngOnInit() {
    this.initialize();
  }
  initialize() {
    this.initialStatus = this.request.Status;
    this.populateActionDropDown();
  }
  public get showReasonForDenial(): boolean {
    return this.request && (this.request.Status === ApplicationStatusEnum.DeniedByTeamLeader);
  }
  private populateActionDropDown() {
    this.statusOptions = [];
    this.addApplicationStatus(ApplicationStatusEnum.ApprovedByTeamLeader);
    this.addApplicationStatus(ApplicationStatusEnum.DeniedByTeamLeader);
  }
  private addApplicationStatus(val: ApplicationStatusEnum): void {
    const returnValue: IDropDownData = { key: val, value: ApplicationStatusEnumDic[val] };
    this.statusOptions.push(returnValue);
  }
  public get showTemplate(): boolean {
    return !!(this.request && this.request.Status);
  }

}
