import { PawsAuthService } from 'src/app/auth/paws-auth-service';
import { PawsApiService } from 'src/app/services/paws-api/paws-api.service';

const defaults = {
  env: 'dev',
  production: false,
  authServiceType: PawsAuthService,
  apiServiceType: PawsApiService,
  auth: {
    domain: 'sso.test.authrock.com',
    clientId: 'LaxRttX9gdhbaezRAC815fbXKjpC0CPF',
    audience: 'urn:ql-api:paws-at-work-api-dev-203589:Test',
    redirect: 'http://localhost:4200/callback',
    connection: 'azure-mi-corp-rockfin-com'
  },
  api: {
    pawsApi: {
      url: 'https://paws-api-dev.backbone-np.foc.zone'
    }
  },
  ui: {
    timeout: {
      spinner: '3000',
      clearMessage: '5000'
    },
    gridPaginationPageSize: '10'
  }
};

export function mapDefaultEnvironmentValues(
  target: { [property: string]: any } = {},
  override: { [property: string]: any } = defaults
): any {
  if (typeof target !== typeof {}) {
    return target;
  }

  for (const property in override) {
    if (!Object.prototype.hasOwnProperty.call(override, property)) {
      continue;
    }

    target[property] = Object.prototype.hasOwnProperty.call(target, property)
      ? mapDefaultEnvironmentValues(target[property], override[property])
      : override[property];
  }

  return target;
}
