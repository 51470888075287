import { Injectable, Inject } from '@angular/core';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { IRegistrationService } from './registrationService.interface';
import { Observable, BehaviorSubject } from 'rxjs';
import { RegistrationRequest } from 'src/app/models/registrationRequest';
import { ApplicationStatusEnum, ApplicationStatusEnumDic } from 'src/app/models/Enums';
import { TeamMember } from 'src/app/models/teamMember';
import { map } from 'rxjs/operators';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IUpdateRequest } from 'src/app/models/UpdateRequest';
import { IUniqueIdentifier } from 'src/app/models/uniqueIdentifier';
import { IHttpResponse } from 'src/app/models/httpResponse';
import { environment } from 'src/environments/environment';

@Injectable()
export class RegistrationService implements IRegistrationService {
  public DBModel: RegistrationRequest;
  public UIModel: RegistrationRequest;
  private _isLoading: BehaviorSubject<boolean>;
  public isLoading$: Observable<boolean>;
  public isUIModelValid = false;

  constructor(
    @Inject('PawsApiService') private pawsApi: IPawsApiService,
    private notificationService: UserNotificationService
  ) {
    this._isLoading = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this._isLoading.asObservable();
  }
  private clearModels(): void {
    this.DBModel = null;
    this.UIModel = null;
  }
  public get isLoading(): boolean {
    return this._isLoading.value;
  }

  submitDogRegistration(): Observable<IHttpResponse> {
    console.log('calling paws api to submit registration data: ' + JSON.stringify(this.UIModel));
    return this.pawsApi.post<RegistrationRequest>(environment.api.pawsApi.url + '/api/saveregistration', this.UIModel);
  }

  public async cancelRegistration(cancelItem: RegistrationRequest) {
    await this.updateRegistrationStatus(ApplicationStatusEnum.CancelledByTeamMember, cancelItem);
  }

  public async updateRegistrationStatus(newStatus: ApplicationStatusEnum, itemToUpdate: RegistrationRequest) {
    try {
      this._isLoading.next(true);
      const updateParams: IUpdateRequest = {
        PartitionKey: itemToUpdate.PartitionKey,
        SortKey: itemToUpdate.SortKey,
        Status: itemToUpdate.Status,
        ReasonforDenial: itemToUpdate.ReasonforDenial,
        NewStatus: newStatus,
        LastUpdateBy: itemToUpdate.TeamMember.TMCommonId
      };
      await this.pawsApi.put<RegistrationRequest>(environment.api.pawsApi.url + '/api/updateRegistrationStatus', updateParams).toPromise();
      this.notificationService.addSuccessMessage('Registration Request status was updated successfully.');
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }

  public async getRegistrationRequest(partitionKey: string, sortKey: string) {
    try {
      this.clearModels();
      this._isLoading.next(true);
      const params: IUniqueIdentifier = {
        PartitionKey: partitionKey,
        SortKey: sortKey
      };
      const registrationRequest = await this.pawsApi.get<RegistrationRequest, IUniqueIdentifier>(
        environment.api.pawsApi.url + '/api/getregistration', params)
        .pipe(
          map(response => {
            const request = Object.assign(new RegistrationRequest(), response);
            request.TeamMember = Object.assign(new TeamMember(), response.TeamMember);
            request.CreateDate = new Date(request.CreateDate);
            request.RabiesExpiryDate = new Date(request.RabiesExpiryDate);
            request.BordetellaExpiryDate = new Date(request.BordetellaExpiryDate);
            request.Status = ApplicationStatusEnumDic[request.Status];
            return request;
          })
        ).toPromise();
      this.assignRegistrationRequest(registrationRequest);
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }

  public assignRegistrationRequest(registrationRequest: RegistrationRequest): void {
    this.DBModel = registrationRequest;
    this.UIModel = Object.assign(new RegistrationRequest(), this.DBModel);
  }
  public buildNewRegistrationRequest(teamMember: TeamMember): void {
    const _teamMember = Object.assign(new TeamMember(), teamMember);
    this.assignRegistrationRequest(new RegistrationRequest(_teamMember));
  }
}
