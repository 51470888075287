import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en-US');
const floorRegex = /^[0-9]+/;

export const dateValueFormatter = (params: ValueFormatterParams) =>
  datePipe.transform(params.value, 'MM/dd/yyyy', 'UTC');

export const floorComparator = (a: unknown, b: unknown) => {
  const floorA = a &&
    typeof a === 'string' &&
    floorRegex.test(a) &&
    a.match(floorRegex)[0];

  const floorB = b &&
    typeof b === 'string' &&
    floorRegex.test(b) &&
    b.match(floorRegex)[0];

  // Check numerics found and compare them
  if (floorA && floorB) {
    const castedA = +floorA;
    const castedB = +floorB;

    return castedA < castedB ? -1
      : castedA > castedB ? 1
        : 0;
  }

  // If one was found to start with numbers, assume the string one comes first
  // Otherwise string comparison (default behavior)
  return !floorA && floorB ? -1
    : floorA && !floorB ? 1
      : a < b ? -1
        : a > b ? 1
          : 0;
};

export const registrationRequestGridColumnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'TeamMember.TMName',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Status',
    field: 'Status',
    sortable: true,
    filter: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Create Date',
    field: 'CreateDate',
    sortable: true,
    filter: true,
    valueFormatter: dateValueFormatter,
  },
  {
    headerName: 'Team',
    field: 'TeamMember.TMSubTeam',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Company',
    field: 'TeamMember.TMCompany',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Building',
    field: 'TeamMember.Location',
    sortable: true,
    filter: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Floor',
    field: 'TeamMember.TMFloor',
    sortable: true,
    filter: true,
    comparator: floorComparator,
  },
  {
    headerName: 'Action',
    cellRenderer: 'redirectToUpdateDetailComponent',
    field: 'TeamMember',
  },
];

export const scheduleRequestGridColumnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'TeamMember.TMName',
    sortable: true,
    filter: true,
    minWidth: 80
  },
  {
    headerName: 'Schedule Date',
    field: 'scheduleDate',
    sortable: true,
    filter: true,
    valueFormatter: dateValueFormatter,
    minWidth: 120
  },
  {
    headerName: 'Status',
    field: 'Status',
    sortable: true,
    filter: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Building',
    field: 'TeamMember.Location',
    sortable: true,
    filter: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Floor',
    field: 'TeamMember.TMFloor',
    sortable: true,
    filter: true,
    comparator: floorComparator,
  },
  {
    headerName: 'Assignment',
    field: 'teamMemberAssignment',
    sortable: true,
    filter: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Team',
    field: 'TeamMember.TMSubTeam',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Company',
    field: 'TeamMember.TMCompany',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Action',
    cellRenderer: 'redirectToUpdateDetailComponent',
    field: 'TeamMember',
  },
];
