<div class="row">
    <div class="col-6 form-group" *ngIf="buildings">
        <label for="TMLocation" [class.required-field]="isRequired">Building Name</label>
        <select id="TMLocation" name="TMLocation" class="form-control" [(ngModel)]="building"
            (change)="onBuildingChange()" [required]="isRequired">
            <option [value]="item.key" *ngFor="let item of buildings">{{item.value}}</option>
        </select>
        <div class="invalid-feedback">{{buildingValidationText}}</div>
    </div>
    <div class="col-6 form-group" *ngIf="floors">
        <label for="TMFloor" [class.required-field]="isRequired">Floor</label>
        <select id="TMFloor" name="TMFloor" class="form-control" [(ngModel)]="floor" (change)="onFloorChange()"
            [disabled]="!building" [required]="isRequired">
            <option [value]="item" *ngFor="let item of floors">{{item}}</option>
        </select>
        <div class="invalid-feedback">{{floorValidationText}}</div>
    </div>
</div>
