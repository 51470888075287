import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(private currentUserService: CurrentUserService) {
  }

  ngOnInit(): void {
  }

  public get showProfileDropdown(): boolean {
    return !!this.currentUserService.teamMember;
  }

  public get teamThumbnailSrc(): string {
    return `https://rockworldapps/badgephotos/${this.currentUserService.teamMember.TMCommonId}.jpg`;
  }

  public get showAdminLinks(): boolean {
    return this.currentUserService.isAdmin;
  }

  public get showImpersonationLink(): boolean {
    return !environment.production && (this.currentUserService.isAdmin || this.currentUserService.isImpersonating);
  }

  public get showLeaderLinks(): boolean {
    return this.currentUserService.isLeader;
  }
}
