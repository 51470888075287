<fieldset ngModelGroup="tmacknowledgement">
  <legend class="h4">Team Member Acknowledgment</legend>
  <p>
    Please read the following guideposts and click on the boxes to proceed with
    your application. Please note that these are not all of the terms and conditions of the Paws at Work
    program. <strong>Please refer to all of the program guidelines <a style="color: #C8102E;"
        href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sys_kb_id=cabd4c58874e1594d5d1ec609bbb35df&spa=1"
        target="blank">here</a> for more
      information</strong>. Approval is granted at the
    discretion of the team leader and can be revoked at any time.
  </p>
  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="VaccinationsComplete" name="VaccinationsComplete"
        #VaccinationsComplete="ngModel" [(ngModel)]="request.VaccinationsComplete" [disabled]="disabled">
      <label class="custom-control-label" for="VaccinationsComplete">
        <span class="required-field">Vaccinations, including bordetella and rabies are complete and current.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="ParasiteControl" name="ParasiteControl"
        #ParasiteControl="ngModel" [(ngModel)]="request.ParasiteControl" [disabled]="disabled">
      <label class="custom-control-label" for="ParasiteControl">
        <span class="required-field">Parasite control is being done on a routine basis and my clean, fluffy puppy is
          free of ticks and fleas.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="NoInfectionOrRingworm"
        name="NoInfectionOrRingworm" #NoInfectionOrRingworm="ngModel" [(ngModel)]="request.NoInfectionOrRingworm"
        [disabled]="disabled">
      <label class="custom-control-label" for="NoInfectionOrRingworm">
        <span class="required-field">My dog has no recent history dating six months or later of infection or
          ringworm.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="NoBiting" name="NoBiting" #NoBiting="ngModel"
        [(ngModel)]="request.NoBiting" [disabled]="disabled">
      <label class="custom-control-label" for="NoBiting">
        <span class="required-field">My dog has no history in their lifetime of biting a human or other animal.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="HouseTrained" name="HouseTrained"
        #HouseTrained="ngModel" [(ngModel)]="request.HouseTrained" [disabled]="disabled">
      <label class="custom-control-label" for="HouseTrained">
        <span class="required-field">My dog is fully house trained.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="WillDoCleanup" name="WillDoCleanup"
        #WillDoCleanup="ngModel" [(ngModel)]="request.WillDoCleanup" [disabled]="disabled">
      <label class="custom-control-label" for="WillDoCleanup">
        <span class="required-field">I am solely responsible for all clean-up inside and outside the building and I will
          immediately clean and disinfect any accidents or messes my dog has accidentally made while in the work
          place.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="DogBehaviour" name="DogBehaviour"
        #DogBehaviour="ngModel" [(ngModel)]="request.DogBehaviour" [disabled]="disabled">
      <label class="custom-control-label" for="DogBehaviour">
        <span class="required-field">I will not bring my dog to work when he/she is ill or is behaving abnormally and
          will remove the dog immediately when it becomes ill or is behaving abnormally.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="DogControl" name="DogControl"
        #DogControl="ngModel" [(ngModel)]="request.DogControl" [disabled]="disabled">
      <label class="custom-control-label" for="DogControl">
        <span class="required-field">I will keep my dog in control at all times using a leash, 6ft or shorter.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="DogPermissionsToCommonArea"
        name="DogPermissionsToCommonArea" #DogPermissionsToCommonArea="ngModel"
        [(ngModel)]="request.DogPermissionsToCommonArea" [disabled]="disabled">
      <label class="custom-control-label" for="DogPermissionsToCommonArea">
        <span class="required-field">I understand that my dog is not permitted in common areas such as restrooms,
          Wellness Center, Daycare Center, cafeterias or other restricted public areas.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="ExcessiveBarking" name="ExcessiveBarking"
        #ExcessiveBarking="ngModel" [(ngModel)]="request.ExcessiveBarking" [disabled]="disabled">
      <label class="custom-control-label" for="ExcessiveBarking">
        <span class="required-field">I will proactively address distractions such as excessive barking around my work
          area.</span>
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="PawsAtWorkPolicy" name="PawsAtWorkPolicy"
        #PawsAtWorkPolicy="ngModel" [(ngModel)]="request.PawsAtWorkPolicy" [disabled]="disabled">
      <label class="custom-control-label" for="PawsAtWorkPolicy">
        <span class="required-field">I have read and understand the Paws At Work <a
            href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sys_kb_id=1e70e9f91bbba950a480542d1e4bcb18&spa=1" target="_blank">policy
            guidelines.</a></span>
      </label>
    </div>
  </div>
  <div class="form-group">
    <p>
      <strong>I understand that I am completely responsible for my dog at all times, including any injury or damage
        caused by my dog. I agree to release and hold harmless the company and other team members, visitors, or guests
        from any
        injuries or damage to property associated with bringing my dog to work.</strong>
    </p>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" required class="custom-control-input" id="TMFinalAcknowledgement"
        name="TMFinalAcknowledgement" #TMFinalAcknowledgement="ngModel" [(ngModel)]="request.TMFinalAcknowledgement"
        [disabled]="disabled">
      <label class="custom-control-label" for="TMFinalAcknowledgement">
        <span class="required-field">Yes</span>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-lg-6">
      <label for="TMSignature" class="required-field">Your Full Name</label>
      <input id="TMSignature" required class="form-control" name="TMSignature" #TMFullName="ngModel"
        [(ngModel)]="request.TeamMember.TMSignature" [disabled]="disabled"
        (change)="request.TeamMember.TMSignature=request.TeamMember.TMSignature.trim()" />
    </div>
  </div>
  <p>
    <strong>Note: Please be considerate of other team members around you along with
      guests/visitors who frequent our buildings. They may be fearful or allergic to dogs. Remember, your
      privileges can be revoked at any time.</strong>
  </p>
</fieldset>