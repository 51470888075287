<h4>Dog Medical Information</h4>
<p>
  Download dog vaccine documents.
</p>
<form>
  <div class="form-row">
    <div class="form-group col-lg-6">
      <label>Bordetella Vaccine Document</label>
      <div class="input-group">
        <a id="downloadBordetellaDocReference" id="downloadBordetellaDocReference" class="btn btn-secondary"
          href="{{request.BordetellaDocReference}}" target="_blank">Download</a>
      </div>
    </div>
    <app-date-picker-form-group class="col-lg-6" label="Expiration Date" key="BordetellaExpiryDate"
      [(value)]="request.BordetellaExpiryDate" required="true"
      invalidFeedback="Please select the expiration date of your dog's Bordetella vaccination." [disabled]="true">
    </app-date-picker-form-group>
  </div>
  <div class="form-row">
    <div class="form-group col-lg-6">
      <label>Rabies Vaccine Document</label>
      <div class="input-group">
        <a id="downloadRabiesDocReference" id="downloadRabiesDocReference" class="btn btn-secondary"
          href="{{request.RabiesDocReference}}" target="_blank">Download</a>
      </div>
    </div>
    <app-date-picker-form-group class="col-lg-6" label="Expiration Date" key="RabiesExpiryDate"
      [(value)]="request.RabiesExpiryDate" required="true"
      invalidFeedback="Please select the expiration date of your dog's rabies vaccination." [disabled]="true">
    </app-date-picker-form-group>
  </div>
</form>
