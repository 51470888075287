<fieldset ngModelGroup="scheduleInformation">
    <legend class="h3">Scheduling Information</legend>
    <p>
        <strong>Please note:</strong>
        Scheduling restrictions do apply dependent on the building and floor you currently occupy. Requests will
        be
        reviewed and processed in the order they are received. Although our team does work on all requests with
        a
        sense of urgency there is no guaranteed turn around time. You will be notified when our team is able to
        process!
    </p>
    <div class="form-row">
        <div class="form-group col-12 col-lg-4">
            <label for="is-approved-user" class="required-field">Are you approved for Paws at Work
                program?</label>
            <select id="is-approved-user" class="form-control" name="isApprovedUser"
                [(ngModel)]="request.isApprovedUser" required [disabled]="disabled">
                <!--
                        The option "No" must come first. The value attribute must be unassigned or an empty string.
                        If the option "No" does not first, then it will not qualify as the select element's placeholder
                        labal option and therefore not be consider an invalid option when the form is validated.

                        More information here: https://html.spec.whatwg.org/multipage/form-elements.html#placeholder-label-option
                    -->
                <option value>No</option>
                <option>Yes</option>
            </select>
            <div class="invalid-feedback">
                You are unable to schedule to bring your dog to work until you've submitted an
                Application Request and the Pulse has approved it.
            </div>
            <small class="form-text text-muted">
                If you have not applied for the Paws at Work program, you can do so by clicking
                <a routerLink="/paw-registration">here</a>.
            </small>
        </div>
        <app-date-picker-form-group class="col-12 col-lg-3" label="When will you bring your dog in?" key="scheduleDate"
            [(value)]="request.scheduleDate" required="true"
            invalidFeedback="Please select a date to bring your dog in." [disabled]="disabled">
        </app-date-picker-form-group>
        <div class="form-group col-12 col-lg-1"></div>
        <!-- Personal Office checkbox -->
        <!-- <div class="form-group col-12 col-lg-4">
            <span>Will you be using a personal office for your dog?</span>
            <input type="checkbox" id="hasOfficeCheckBox" name="hasOffice" value="request.hasOffice" [(ngModel)]="request.hasOffice" [disabled]="disabled">
            <small class="form-text text-muted">
                If you have a personal office and want to keep your dog there instead of using one of
                 the designated Paws Hotel Desks, check this box.
            </small>

        </div> -->
    </div>
</fieldset>
