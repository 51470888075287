<label class="required-field">{{headerText}}<a style="cursor: pointer;" data-toggle="tooltip"
      title="Please ensure the document has one of the following formats: .docx,.doc,.txt,.rtf,.odt,.pdf,.xls,.xlsx,.csv,.html,.jpg,.png,.jpeg,.heif,.heic"> 🛈</a></label>
<div class="custom-file">
  <input type="file" class="custom-file-input" name="uploadControl" id="uploadControl" (change)="onFileChange($event)"
    required accept=".docx,.doc,.txt,.rtf,.odt,.pdf,.xls,.csv,.html,.jpg,.png,.jpeg,.heif,.heic"
    [class.is-invalid]="isInvalid">
  <label class="custom-file-label" for="uploadControl">
    {{file ? file.name : "Choose file"}}
  </label>
  <div class="invalid-feedback">
    {{validationText}}
  </div>
</div>