<app-jumbotron header="My Team Dashboard" message="See applications submitted by your team members.">
</app-jumbotron>
<div class="container">
  <form>
    <div class="row">
      <div class="col-3 form-group">
        <label for="TMFirstName">First Name</label>
        <input type="text" id="TMFirstName" name="TMFirstName" class="form-control"
          [(ngModel)]="searchParams.TMFirstName" />
      </div>
      <div class="col-3 form-group">
        <label for="TMLastName">Last Name</label>
        <input type="text" id="TMLastName" name="TMLastName" class="form-control"
          [(ngModel)]="searchParams.TMLastName" />
      </div>
      <div class="col-4 form-group">
        <label for="registration-status">Status</label>
        <select id="registration-status" class="form-control" name="registration-status"
          [(ngModel)]="searchParams.Status">
          <option [ngValue]="option.key" *ngFor="let option of statusOptions">{{option.value}}</option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary mr-1" type="submit" (click)="applyFilters()" id="ApplyFilter">Apply Filter</button>
      <button class="btn btn-secondary" (click)="resetFilters()" id="ClearFilter">Clear Filter</button>
    </div>
  </form>
  <div class="mt-4">
    <app-grid paginationPageSize="10" [columnDefs]="registrationRequestGridColumnDefs" [rowData]="registrationRequests"
      noRowsMessage="Sorry. No registration requests were found for the filtered conditions."
      [frameworkComponents]="registrationRequestGridFrameworkComponents">
    </app-grid>
  </div>
</div>
