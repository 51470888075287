import { ColDef } from 'ag-grid-community';
import { Component, Inject, OnInit } from '@angular/core';
import { IDropDownData } from 'src/app/models/common';
import { RequestTypeEnum, ScheduleStatusEnum, ScheduleStatusEnumDic } from 'src/app/models/Enums';
import { scheduleRequestGridColumnDefs } from 'src/app/models/grid-column-defs';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { ISearchParams } from 'src/app/models/SearchParams';
import {
    ScheduleRequestSearchService
} from 'src/app/services/search/schedule-request-search.service';
import {
    RedirectToUpdateDetailComponent
} from '../custom/redirect-to-update-detail/redirect-to-update-detail.component';

@Component({
  selector: 'app-admin-schedule-dashboard',
  templateUrl: './admin-schedule-dashboard.component.html'
})
export class AdminScheduleDashboardComponent implements OnInit {
  public scheduleRequestGridColumnDefs: ColDef[] = scheduleRequestGridColumnDefs;
  public scheduleRequestGridFrameworkComponents: any = { redirectToUpdateDetailComponent: RedirectToUpdateDetailComponent };
  public requestType = RequestTypeEnum.ScheduleRequest;
  private apiEndpoint = '/api/searchschedule';
  statusOptions: IDropDownData[];
  constructor( @Inject('AdminSchedule') private scheduleRequestSearchService: ScheduleRequestSearchService,) { }

  ngOnInit() {
    this.resetFilters();
    this.populateDropDown();
  }

  populateDropDown() {
    this.statusOptions = [];
    this.statusOptions.push({ key: '', value: 'All' });
    this.addApplicationStatus(ScheduleStatusEnum.PendingPulseApproval);
    this.addApplicationStatus(ScheduleStatusEnum.ApprovalEmailSent);
    this.addApplicationStatus(ScheduleStatusEnum.DenialEmailSent);
    this.addApplicationStatus(ScheduleStatusEnum.CancelEmailSent);
  }

  private addApplicationStatus(val: ScheduleStatusEnum): void {
    const returnValue: IDropDownData = { key: val, value: ScheduleStatusEnumDic[val] };
    this.statusOptions.push(returnValue);
  }

  public get searchParams(): ISearchParams {
    return this.scheduleRequestSearchService.searchParams;
  }

  public resetFilters(): void {
    this.scheduleRequestSearchService.searchParams = { Status: ScheduleStatusEnum.PendingPulseApproval };
    this.scheduleRequestSearchService.loadScheduleRequestsBySearchParams(this.apiEndpoint);
  }

  public applyFilters(): void {
    this.scheduleRequestSearchService.loadScheduleRequestsBySearchParams(this.apiEndpoint);
  }

  public get scheduleRequests(): ScheduleRequest[] {
    return this.scheduleRequestSearchService.data;
  }
}
