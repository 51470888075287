import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TeamMember } from 'src/app/models/teamMember';

@Component({
  selector: 'app-tm-autopopulate',
  templateUrl: './tm-autopopulate.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class TmAutopopulateComponent implements OnInit {
  @Input() teamMember: TeamMember;
  @Input() createDate: Date;

  ngOnInit() {
  }

}
