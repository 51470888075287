import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { RegistrationRequest } from 'src/app/models/registrationRequest';

@Component({
  selector: 'app-tm-acknowledgment',
  templateUrl: './tm-acknowledgment.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TmAcknowledgmentComponent implements OnInit {
  @Input() public disabled: boolean;

  constructor(private registrationService: RegistrationService) { }
  ngOnInit() {
  }

  public get request(): RegistrationRequest {
    return this.registrationService.UIModel;
  }
}
