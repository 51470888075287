<div class="jumbotron jumbotron-fluid py-3 mb-3 " [ngStyle]="!header && {'background-color': 'white'}">
  <div class="container">
    <div class="row" *ngIf="header">
      <div class="col-12 col-md-12 d-flex flex-column justify-content-center align-items-center">
        <h1><strong>{{header}}</strong></h1>
        <p *ngIf ="message" class="lead">{{message}}</p>
      </div>
    </div>
  </div>
</div>  
