import { map as lodashMap, orderBy as lodashOrderBy, uniqBy as lodashUniqBy } from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ScheduleStatusEnum, ScheduleStatusEnumDic } from 'src/app/models/Enums';
import { ScheduleRequest } from 'src/app/models/schedule-request';
import { ISearchParams } from 'src/app/models/SearchParams';
import { TeamMember } from 'src/app/models/teamMember';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IScheduleRequestSearchService } from './schedule-request-search.interface';
import { SearchService } from './search.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleRequestSearchService extends SearchService<ScheduleRequest> implements IScheduleRequestSearchService {
  constructor(
    @Inject('PawsApiService') apiService: IPawsApiService,
    notificationService: UserNotificationService
  ) {
    super(apiService, notificationService);
  }

  public async loadScheduleRequestsBySearchParams(endpoint: string) {
    try {
      this._isLoading.next(true);
      this._data = await this.apiService.get<ScheduleRequest[], ISearchParams>(
        environment.api.pawsApi.url + endpoint, this.searchParams)
        .pipe(
          map(response => {
            const scheduleRequests = lodashMap(response, this.SetMappingValues
            );
            return lodashOrderBy(
              scheduleRequests,
              (request) => [request.Status, new Date(request.scheduleDate)], ['asc', 'desc']
            );
          })
        ).toPromise();
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
  public async loadScheduleRequestsBySearchParamsWithMultipleStatus(endpoint: string, statuses: ScheduleStatusEnum[]) {
    try {
      this._isLoading.next(true);
      const promises = [];
      if (statuses.length === 0) {
        return;
      }
      statuses.forEach(currentstatus => {
        const apiParams = JSON.parse(JSON.stringify(this.searchParams)) as ISearchParams;
        apiParams.Status = currentstatus;
        promises.push(this.apiService.get<ScheduleRequest[], ISearchParams>(
          environment.api.pawsApi.url + endpoint, apiParams).toPromise());
      });
      this._data = lodashOrderBy(
        lodashMap(
          lodashUniqBy(
            Array.prototype.concat.apply([], await Promise.all(promises)) as ScheduleRequest[]
            , (item) => [item.PartitionKey, item.SortKey].join())
          , this.SetMappingValues
        ),
        (request) => [request.Status, new Date(request.scheduleDate)], ['asc', 'desc']);
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
  private SetMappingValues = (item: ScheduleRequest): ScheduleRequest => {
    const scheduleRequest = Object.assign(new ScheduleRequest(), item);
    scheduleRequest.TeamMember = Object.assign(new TeamMember(), item.TeamMember);
    scheduleRequest.Status = ScheduleStatusEnumDic[scheduleRequest.Status];
    return scheduleRequest;
  };
}
