<div *ngFor="let msg of notifications">
  <div [ngClass]="[
  msg.MessageType === 1 ? 'alert alert-success': '',
  msg.MessageType === 2 ? 'alert alert-warning': '',
  msg.MessageType === 3 ? 'alert alert-danger': ''
    ]" role="alert">
    <button type="button" class="close" data-dismiss="alert">
      <span aria-hidden="true" (click)="clearMessage(msg.MessageId)">&times;</span>
    </button>
    <strong innerHtml="{{msg.UserMessage}}"></strong>
  </div>
</div>
