import { TeamMember } from './teamMember';
import { ScheduleRequest } from './schedule-request';
import { FormBase } from './common';

export class RegistrationRequest extends FormBase {
  DogName?: string; // Dog Name
  DogBreed?: string; // Dog Breed
  DogWeight?: string; // Dog Weight
  DogAge?: string; // Dog Age
  DogDescription?: string; // Dog Description
  DogNeutered?: string; // Dog Neutered
  BordetellaVaccineDocument?: string; // Store Bordetella document contents
  BordetellaDocReference?: string; // Bordetella document presigned url
  RabiesVaccineDocument?: string; // Store Rabies document contents
  RabiesDocReference?: string; // Rabies document presigned url
  BordetellaExpiryDate?: string | Date; // Bordetella Expiry Date
  RabiesExpiryDate?: string| Date; // Rabies Expiry Date
  VaccinationsComplete?: string; // Vaccinations, including bordetella and rabies are complete and current.
  ParasiteControl?: string; // Parasite control is being done on a routine basis and my clean, fluffy puppy is free of ticks and fleas.
  NoInfectionOrRingworm?: string; // My dog has no recent history dating six months or later of infection or ringworm
  NoBiting?: string; // My dog has no history in their lifetime of biting a human or other animal.
  HouseTrained?: string; // My dog is fully house trained.
  WillDoCleanup?: string; // acknowledgement
  DogBehaviour?: string; // acknowledgement
  DogControl?: string; // acknowledgement
  DogPermissionsToCommonArea?: string; // acknowledgement
  ExcessiveBarking?: string; // I will proactively address distractions such as excessive barking around my work area.
  PawsAtWorkPolicy?: string; // I have read and understand the Paws At Work policy guidelines.
  TMFinalAcknowledgement?: string; // acknowledgement

  constructor(teamMember?: TeamMember) {
    super();
    this.TeamMember = teamMember || new TeamMember();
  }
}
