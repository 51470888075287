import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { RegistrationRequest } from 'src/app/models/registrationRequest';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html'
})
export class DocumentDownloadComponent implements OnInit {

  constructor(private registrationService: RegistrationService) { }

  ngOnInit() {
  }

  public get request(): RegistrationRequest {
    return this.registrationService.UIModel;
  }
}
