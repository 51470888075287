<rkt-masthead>
  <div
    rktMastheadBranding
    rktStackItem
    class="rkt-Masthead__branding rkt-Stack__item--center-column@1"
  >
    <a href="/home">
      <img class="header-logo" src="/assets/images/new-logo-1.png" />
    </a>
  </div>

  <div
    rktMastheadNavItem
    rktStackItem
    class="rkt-Masthead__nav-item rkt-Stack__item--center-column@1"
  >
    <app-profile></app-profile>
  </div>

  <!-- large view site links -->
  <nav
    rktMastheadNavItems
    rktStackItem
    role="navigation"
    aria-label="Navigation Links"
    class="
      rkt-Masthead__nav-items
      rkt-Stack__item--flex@1
      rkt-Stack rkt-Stack--space-4 rkt-Stack--split@1 rkt-Stack--end-row
    "
  >
    <ul
      rktStackItem
      class="
        rkt-stack-item
        rkt-mt0 rkt-mt0 rkt-pl0
        rkt-Stack--center-column
        rkt-ListStyle--none
        rkt-Stack rkt-Stack--space-4 rkt-Stack--split@1
        rkt-Width--auto
      "
    >
      <li rktStackItem>
        <a routerLink="/paw-registration" routerLinkActive="active" rktLink variant="simple" class="rkt-Masthead__link">
          Apply
        </a>
      </li>

      <li rktStackItem>
        <a routerLink="/paw-schedule" routerLinkActive="active" rktLink variant="simple" class="rkt-Masthead__link">
          Schedule
        </a>
      </li>

      <li rktStackItem>
        <a routerLink="/calendar" routerLinkActive="active" rktLink variant="simple" class="rkt-Masthead__link">
          Calendar
        </a>
      </li>

      <app-profile class="profile"></app-profile>
    </ul>
  </nav>

  <!-- Small View Collapsible Nav -->
  <nav
    rktMastheadNavCollapsible
    role="navigation"
    aria-label="Collapsible Navigation"
    idString="default-collapsible-nav"
  >
    <!-- Small View Nav Accordion -->
    <mat-accordion class="rkt-mv3 rkt-Display--block">
      <div
        class="
          rkt-AccordionPanel rkt-AccordionPanel--nav
          mat-expansion-panel
          rkt-Elevation-0
        "
      >
        <div class="mat-expansion-panel-header">
          <a
            class="mat-expansion-panel-header-title rkt-TextDecoration--none"
            href="/paw-registration"
          >
            Apply
          </a>
        </div>
      </div>

      <div
        class="
          rkt-AccordionPanel rkt-AccordionPanel--nav
          mat-expansion-panel
          rkt-Elevation-0
        "
      >
        <div class="mat-expansion-panel-header">
          <a
            class="mat-expansion-panel-header-title rkt-TextDecoration--none"
            href="/paw-schedule"
          >
            Schedule
          </a>
        </div>
      </div>

      <div
        class="
          rkt-AccordionPanel rkt-AccordionPanel--nav
          mat-expansion-panel
          rkt-Elevation-0
        "
      >
        <div class="mat-expansion-panel-header">
          <a
            class="mat-expansion-panel-header-title rkt-TextDecoration--none"
            href="/calendar"
          >
            Calendar
          </a>
        </div>
      </div>
    </mat-accordion>
  </nav>
</rkt-masthead>
