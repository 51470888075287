import { FormBase } from './common';
import { TeamMember } from './teamMember';

export class ScheduleRequest extends FormBase {
    public scheduleDate?: Date;
    public isApprovedUser?: string;
    public hasOffice?: boolean;
    public seating?: string;
    public teamMemberAssignment?: string;

    constructor(teamMember?: TeamMember) {
        super();
        this.TeamMember = teamMember || new TeamMember();
    }
}
