import { Injectable, Inject } from '@angular/core';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IUserRoleRequest } from 'src/app/models/userRoleRequest';
import { IUserRole } from 'src/app/models/userRole';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ICurrentUserService } from './current-user.service.interface';
import { TeamMember } from 'src/app/models/teamMember';
import { IAuthService } from 'src/app/auth/auth-service.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class CurrentUserService implements ICurrentUserService {
  public isAdmin = false;
  public isLeader = false;

  private _impersonatingCommonId: string;
  private _isLoading: BehaviorSubject<boolean>;
  public isLoading$: Observable<boolean>;
  private _teamMember: TeamMember;

  constructor(
    @Inject('PawsAuthService') private authService: IAuthService,
    @Inject('PawsApiService') private pawsApi: IPawsApiService,
    private notificationService: UserNotificationService
  ) {
    this._isLoading = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this._isLoading.asObservable();

    this.initialize();
  }

  public get teamMember(): TeamMember {
    if (this.isImpersonating) {
      this._teamMember.TMCommonId = this.impersonatingCommonId;
    }
    return this._teamMember;
  }

  public get impersonatingCommonId(): string {
    return this._impersonatingCommonId;
  }

  public set impersonatingCommonId(commonIdToImpersonate: string) {
    this._impersonatingCommonId = commonIdToImpersonate;
    this.initialize();
  }

  public get isImpersonating(): boolean {
    return !!this.impersonatingCommonId;
  }

  public initialize(): void {
    this.authService.currentUser$.subscribe((user) => {
      if (user.TMCommonId) {
        this._teamMember = Object.assign(new TeamMember(), user);
        const param: IUserRoleRequest = { TMCommonId: this.teamMember.TMCommonId };
        this.setUserRole(param);
      }
    });
  }

  public get isLoading(): boolean {
    return this._isLoading.value;
  }

  private async setUserRole(params: IUserRoleRequest) {
    try {
      this._isLoading.next(true);
      const roleInfo = await this.pawsApi.get<IUserRole, IUserRoleRequest>(
        environment.api.pawsApi.url + '/api/checkUserRole', params).pipe(first()).toPromise();
      this.isAdmin = roleInfo.IsAdmin;
      this.isLeader = roleInfo.IsLeader;
      this._teamMember.TMExtension = roleInfo.Extension;
      this._teamMember.TeamName = roleInfo.BusinessArea;
      if (roleInfo.TeamLeader) {
        this._teamMember.TeamLeaderName = roleInfo.TeamLeader.FirstName + ' ' + roleInfo.TeamLeader.LastName;
        this._teamMember.TeamLeaderCommonId = roleInfo.TeamLeader.CommonId;
        this._teamMember.TeamLeaderEmail = roleInfo.TeamLeader.EmailAddress;
      }
    } catch (err) {
      this.notificationService.handleError(err);
    } finally {
      this._isLoading.next(false);
    }
  }
}
