import { BehaviorSubject, Observable, of } from 'rxjs';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { ISearchParams } from 'src/app/models/SearchParams';
import { IUserNotificationService } from '../user-notification/user-notification.interface';
import { ISearchService } from './search.service.interface';

export class SearchService<T> implements ISearchService<T> {
  protected _isLoading: BehaviorSubject<boolean>;
  public isLoading$: Observable<boolean>;
  public _data: T[];
  public searchParams: ISearchParams;

  constructor(protected apiService: IPawsApiService, protected notificationService: IUserNotificationService) {
    this._isLoading = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this._isLoading.asObservable();
  }

  public get isLoading(): boolean {
    return this._isLoading.value;
  }

  public get data(): T[] {
    return this._data || [];
  }
}
