<app-jumbotron header="My Dashboard">
</app-jumbotron>
<div class="container">
  <div id="accordion" class="accordion">
    <div class="card" id="scheduleRequests">
      <button class="card-header btn btn-link" id="scheduleHeader" data-toggle="collapse"
        data-target="#collapseSchedules">My Schedule Requests</button>
      <ul class="list-group list-group-flush list-group-item-success collapse show" id="collapseSchedules">
        <li class="list-group-item" *ngFor="let item of scheduleRequests">
          <div class="row">
            <dl class="col-lg-3">
              <dt>Name</dt>
              <dd>{{item.TeamMember.TMName}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Job Title</dt>
              <dd>{{item.TeamMember.TMJobTitle}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Team</dt>
              <dd>{{item.TeamMember.TMSubTeam}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Schedule Date</dt>
              <dd>{{item.scheduleDate | date:'mediumDate':'+0000'}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Floor</dt>
              <dd>{{item.TeamMember.TMFloor}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Building</dt>
              <dd>{{item.TeamMember.Location}}</dd>
            </dl>
            <dl class="col-6">
              <dt>Status</dt>
              <dd>{{item.Status}}</dd>
            </dl>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" id="btnScheduleCancel" name="btnScheduleCancel"
              (click)="onScheduleCancel(item)">Cancel</button>
          </div>
        </li>
      </ul>
    </div>
    <div class="card" id="applicationRequests">
      <button class="card-header btn btn-link" id="applicationHeader" data-toggle="collapse"
        data-target="#collapseApplications">My Application Requests</button>
      <ul class="list-group list-group-flush list-group-item-success collapse show" id="collapseApplications">
        <li class="list-group-item" *ngFor="let item of registrationRequests">
          <div class="row">
            <dl class="col-lg-3">
              <dt>Name</dt>
              <dd>{{item.TeamMember.TMName}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Vaccinations Completed</dt>
              <dd>{{item.VaccinationsComplete ? 'Yes' : 'No'}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Job Title</dt>
              <dd>{{item.TeamMember.TMJobTitle}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Team</dt>
              <dd>{{item.TeamMember.TMSubTeam}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Dog Name</dt>
              <dd>{{item.DogName}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Dog Breed</dt>
              <dd>{{item.DogBreed}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Bordetella Expiration Date</dt>
              <dd>{{item.BordetellaExpiryDate | date:'mediumDate':'+0000'}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Rabies Expiration Date</dt>
              <dd>{{item.RabiesExpiryDate | date:'mediumDate':'+0000'}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Building</dt>
              <dd>{{item.TeamMember.Location}}</dd>
            </dl>
            <dl class="col-lg-3">
              <dt>Company</dt>
              <dd>{{item.TeamMember.TMCompany}}</dd>
            </dl>
            <dl class="col-lg-6">
              <dt>Status</dt>
              <dd>{{item.Status}}</dd>
            </dl>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" id="btnRegistrationCancel" name="btnRegistrationCancel"
              (click)="onRegistrationCancel(item)">Cancel</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>