import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { RegistrationRequest } from 'src/app/models/registrationRequest';

@Component({
  selector: 'app-dog-vaccineupload',
  templateUrl: './dog-vaccineupload.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DogVaccineuploadComponent implements OnInit {
  public isBordetellaVaccineDocumentInvalid = false;
  public isRabiesVaccineDocumentInvalid = false;

  constructor(private registrationService: RegistrationService) { }

  ngOnInit() {

  }

  public get request(): RegistrationRequest {
    return this.registrationService.UIModel;
  }

  public onIsInvalidChange() {
    this.registrationService.isUIModelValid = !this.isBordetellaVaccineDocumentInvalid && !this.isRabiesVaccineDocumentInvalid;
  }
}
