<div class="container">
    <div class="row">
        <div class="col-12 offset-md-1 col-md-10 offset-lg-3 col-lg-6">
            <h3>Impersonation</h3>
            <p>Enter the common ID of the team member you want to impersonate.</p>
            <div class="form-group">
                <label for="common-id">Common ID</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="common-id" #commonIdToImpersonate />
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" (click)="toggleImpersonation(commonIdToImpersonate.value)">
                            Impersonate
                        </button>
                    </div>
                </div>
                <small class="form-text text-muted" *ngIf="showCurrentlyImpersonatingMessage">
                    You are current impersonating common ID {{impersonatingCommonId}}. Click
                    <a id="unimpersonate-link" routerLink="/impersonation" (click)="toggleImpersonation()">here</a> to stop.
                </small>
            </div>
        </div>
    </div>
</div>