import { ColDef } from 'ag-grid-community';
import { Component, Inject, OnInit } from '@angular/core';
import { IDropDownData } from 'src/app/models/common';
import { ApplicationStatusEnum, ApplicationStatusEnumDic } from 'src/app/models/Enums';
import { registrationRequestGridColumnDefs } from 'src/app/models/grid-column-defs';
import { RegistrationRequest } from 'src/app/models/registrationRequest';
import { ISearchParams } from 'src/app/models/SearchParams';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import {
    RegistrationRequestSearchService
} from 'src/app/services/search/registration-request-search.service';
import {
    RedirectToUpdateDetailComponent
} from '../custom/redirect-to-update-detail/redirect-to-update-detail.component';

@Component({
  selector: 'app-tl-dashboard',
  templateUrl: './tl-dashboard.component.html'
})
export class TlDashboardComponent implements OnInit {
  public registrationRequestGridColumnDefs: ColDef[] = registrationRequestGridColumnDefs;
  public registrationRequestGridFrameworkComponents: any = { redirectToUpdateDetailComponent: RedirectToUpdateDetailComponent };
  statusOptions: IDropDownData[];
  constructor(
    @Inject('TLRegistration') private registrationRequestSearchService: RegistrationRequestSearchService,
    private currentUserService: CurrentUserService
  ) {
  }

  ngOnInit() {
    this.resetFilters();
    this.populateDropDown();
  }

  public get searchParams(): ISearchParams {
    return this.registrationRequestSearchService.searchParams;
  }

  populateDropDown() {
    this.statusOptions = [];
    this.statusOptions.push({ key: '', value: 'All' });
    this.addApplicationStatus(ApplicationStatusEnum.PendingTeamLeaderApproval);
    this.addApplicationStatus(ApplicationStatusEnum.ApprovedByTeamLeaderEmailSent);
    this.addApplicationStatus(ApplicationStatusEnum.DeniedByTeamLeaderEmailSent);
    this.addApplicationStatus(ApplicationStatusEnum.DeniedByPulseEmailSent);
    this.addApplicationStatus(ApplicationStatusEnum.ExpiredDocuments);
  }

  private addApplicationStatus(val: ApplicationStatusEnum): void {
    const returnValue: IDropDownData = { key: val, value: ApplicationStatusEnumDic[val] };
    this.statusOptions.push(returnValue);
  }

  public resetFilters(): void {
    this.registrationRequestSearchService.searchParams = {
      TLCommonId: this.currentUserService.teamMember.TMCommonId,
      Status: ApplicationStatusEnum.PendingTeamLeaderApproval
    };
    this.registrationRequestSearchService.loadRegistrationRequestsBySearchParams();
  }

  public applyFilters(): void {
    this.registrationRequestSearchService.loadRegistrationRequestsBySearchParams();
  }

  public get registrationRequests(): RegistrationRequest[] {
    return this.registrationRequestSearchService.data;
  }
}
