import { Inject, Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { TeamMember } from '../models/teamMember';
import { IAuthService } from './auth-service.interface';

@Injectable({
  providedIn: 'root'
})
export class PawsAuthService implements IAuthService {
  private _currentUser = new BehaviorSubject<TeamMember>(new TeamMember());
  private _currentUser$: Observable<TeamMember> = this._currentUser.asObservable();

  constructor(
    @Inject('AuthService') private auth: AuthService
  ) { }

  public get isAuthenticated$(): Observable<boolean> {
    return this.auth.isAuthenticated$;
  }

  public loginWithRedirect(connection: string, redirect: string): Observable<void> {
    return this.auth.loginWithRedirect({
      connection,
      appState: { target: redirect }
    });
  }

  public loadUserInformation(user: User): void {
    const openIdUrl = 'https://ql.custom.openid.com';
    const currentUser = new TeamMember();
    currentUser.TMCommonId = user[`${openIdUrl}/common_id`];
    currentUser.TMFirstName = user.given_name;
    currentUser.TMLastName = user.family_name;
    currentUser.TMEmail = user[`${openIdUrl}/email`];
    currentUser.TMExtension = user[`${openIdUrl}/extension`];
    currentUser.TMJobTitle = user[`${openIdUrl}/job_title`];
    currentUser.TeamName = user[`${openIdUrl}/team`];
    currentUser.TMSubTeam = user[`${openIdUrl}/subteam`];
    currentUser.TMCompany = user[`${openIdUrl}/company_name`];

    this._currentUser.next(currentUser);
  }

  public get user$(): Observable<User> {
    return this.auth.user$;
  }

  public get currentUser$(): Observable<TeamMember> {
    return this._currentUser$;
  }
}
