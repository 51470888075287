<app-jumbotron header="Schedule Details" message="Review and Update the Schedule Requests">
</app-jumbotron>
<div class="container" *ngIf="showTemplate">
  <form #scheduleForm="ngForm" [class.was-validated]="isSubmitted">
    <app-tm-autopopulate [teamMember]="request.TeamMember" isSubmitted="false" [createDate]="request.CreateDate">
    </app-tm-autopopulate>
    <app-schedule-information [disabled]="true"></app-schedule-information>
    <app-pulse-admin-section [request]="request"></app-pulse-admin-section>
    <button class="btn btn-primary" id="btnSave" name="btnSave" (click)="onUpdate(scheduleForm)">
      Save</button>
    <button class="btn btn-secondary ml-1" id="btnCancel" name="btnCancel" routerLink="/admin-schedule-dashboard">Go To
      Dashboard</button>
  </form>
</div>
